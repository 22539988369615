import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { containerStyles, HEADER_HEIGHT_BIG } from 'constants/styles';
import Header from './header/header';

const StyledContentWrapper = styled.main`
  ${containerStyles};
  min-height: calc(100vh - ${HEADER_HEIGHT_BIG}px);
`;

const Layout = () => {
  return (
    <>
      {process.env.REACT_APP_PREVIEW_BUILD && (
        <div className="development-build-header" style={{ textAlign: 'center' }}>
          <h3>
            This is a development build:
            <div />
            <a href={`https://github.com/api3dao/operations/commit/${process.env.REACT_APP_COMMIT_HASH}`}>
              {process.env.REACT_APP_COMMIT_HASH}
            </a>
            <br />
            Note that the URL will not update with navigation in development builds.
          </h3>
        </div>
      )}
      <Header />
      <StyledContentWrapper>
        <Outlet />
      </StyledContentWrapper>
    </>
  );
};

export default Layout;
