export const FILE_CLAIM = 'File a claim';
export const REQUEST_DATA = 'Request data';
export const COULDNT_FIND_RECORDS = 'We couldn’t find any matches for';
export const COULDNT_FIND_RECORDS_DESC = 'Double check your search for any spelling errors or try another term.';
export const EMPTY_TABLE = 'Table is empty';
export const EMPTY_TABLE_DESC = 'Please try later';
export const DAPI_PAGE_TITLE = 'dAPIs';
export const DAPI_PAGE_DESCRIPTION = 'Feeds managed by API3. Something about the best quality';
export const BEACONS_PAGE_TITLE = 'Beacons';
export const BEACONS_PAGE_DESCRIPTION = 'Another sentence about all beacons';
export const MONTHLY_PRICE = 'Monthly Price';
export const PRICE_RANGE = 'Price Range';
export const POWERED_BY = 'Powered by';
export const ON_CHAIN_VALUE = 'On-chain Value';
export const CURRENT_SOURCES = 'Current Sources';
export const VIEW_MORE_PARAMETERS = 'View More Parameters';
export const HISTORY = 'History';
export const DEVIATION = 'Deviation';
export const LAST_TRANSACTIONS_TITLE = 'Recent Transactions';
export const SUBSCRIBE_DESCRIPTION =
  'Subscribing to a data feed allows unlimited calls to the feed contract and limited coverage against malfunctions.';
export const LEARN_MORE_ABOUT_COVERAGE = 'Learn more about coverage';
export const SELECT_COVERAGE = 'Select Coverage';
export const COVER_UP_TO = 'Covered up to';
export const COVERAGE_UP_TO = 'Coverage up to';
export const COVERED_UP_TO = 'Covered up to';
export const MONTHLY_FEE = 'Monthly Fee';
export const TOTAL = 'Total';
export const MATIC = 'Matic';
export const USD = 'USD';
export const ADD_TO_ORDER = 'Add To Order';
export const CLEAR = 'Clear';
export const ORDER = 'Order';
export const MAINNET = 'Mainnet';
export const SUBSCRIPTION_ADDED_TO_ORDER = 'Subscription added to Order';
export const FINALIZE_ORDER = 'Finalize Order';
export const ORDER_PAGE_HEADING = 'How would you like us to contact you?';
export const TELEGRAM_CONTACT_HEADING = 'What would you like your Telegram group to be called?';
export const TELEGRAM_CONTACT_DESCRIPTION =
  'We’ll make a Telegram group with this name for you, so that you know it’s us.';
export const TELEGRAM_GROUP_NAME_PREFIX = 'API3<>';
export const TERMS_AND_CONDITIONS = 'Terms and Conditions';
export const ACCEPT_TERMS_AND_CONDITIONS = 'I have read and agree to the Terms and Conditions';
export const SUBMIT_ORDER = 'Submit Order';
export const SUBMIT_ORDER_THANKS = 'Thank you for submitting your order';
export const TELEGRAM_SUCCESS_DESCRIPTION =
  'Please check Telegram for a group and message from us to continue your order.';
export const EMAIL_SUCCESS_DESCRIPTION = 'Please check your email for a message from us to continue your order.';
export const DISCORD_SUCCESS_DESCRIPTION = 'Please check Discord for friend requests from the API3 team.';
export const RETURN_HOME = 'Return Home';
export const VALUE_COULD_NOT_BE_RETRIEVED = 'Value could not be retrieved';
export const BEACONS_HELMET_TITLE = 'API3 Market - Beacons';
export const DAPIS_HELMET_TITLE = 'API3 Market - dAPIs';
export const ORDER_HELMET_TITLE = 'API3 Market - Order';
export const EMPTY_ORDER = 'Your Order is empty';
export const EMPTY_ORDER_DESCRIPTION = 'Looks like you haven’t added any data feed subscriptions yet!';
export const BROWSE_DATA_FEEDS = 'Browse Data Feeds';
export const COULD_NOT_RETRIEVE_VOLATILITY_DATA = 'Could not retrieve volatility data!';
export const COULD_NOT_RETRIEVE_HISTORY_DATA = 'Could not retrieve history data!';
export const CHECK_BACK_LATER = 'Check back later!';
export const CANCEL = 'Cancel';
export const DATAFEED_ALREADY_IN_ORDER = 'You already have this data feed in your order';
export const COPIED = 'Copied!';
export const SUBSCRIBE_HEADER = 'Select your Quantifiable Security';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const TRY_RELOAD_THE_PAGE = 'Try reload the page';
export const NO_VALUE = '—';
export const TRIAL_UNTIL = 'usage until 30 September, 2022';
