import React, { FormEvent, FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import SimpleBar from 'simplebar-react';
import { TimesIcon } from 'components/shared/icons';
import { Button, CustomCheckbox, Spinner } from 'components/shared';
import { EButtonVariants } from 'components/shared/button/button.entities';
import { ACCEPT_TERMS_AND_CONDITIONS, SUBMIT_ORDER, TERMS_AND_CONDITIONS } from 'constants/messages';

const StyledWrapper = styled.div`
  position: relative;
  padding: 80px;
  width: 560px;
  background-color: #030303;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(76.31deg, #7ce3cb 2.01%, #7963b2 84.37%);

  .popup-close {
    position: absolute;
    top: 42px;
    right: 42px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }

  @media only screen and (max-width: 560px) {
    width: 100vw;
    padding-right: 24px;
    padding-left: 24px;
  }

  @media only screen and (max-width: 361px) {
    height: 100vh;
    .order-submit-btn {
      display: block;
      width: 100%;
    }

    .popup-close {
      top: 32px;
      right: 22px;
    }
  }
`;

const StyledTitle = styled.div`
  font-weight: 300;
  font-size: 27px;
  line-height: 44px;
  text-align: center;
  color: #c3c4c3;
  margin-bottom: 24px;
`;

const StyledConditions = styled.div`
  color: var(--font-color-white);
  padding: 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  background: #121313;
  box-shadow: inset 0 0 10px #000000;
  position: relative;

  .simplebar-wrapper {
    position: relative;

    .simplebar-scrollbar:before {
      background: rgba(108, 109, 109, 1);
      opacity: 1 !important;
    }

    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 100%;
      border-right: 2px solid rgba(34, 34, 34, 1);
      right: 4px;
      top: 0;
      padding: 50px 0;
      z-index: 0;
    }
  }
`;

const StyledAcceptWrapper = styled.div<{ checked: boolean }>`
  margin: 20px 0 50px 0;

  input {
    margin-right: 8px;
    background-color: #121313;
    border: 1px solid #222222;
  }

  label {
    color: #878888;

    ${({ checked }) =>
      checked &&
      css`
        color: #c3c4c3;
      `}
  }
`;

const StyledSubmitOrder = styled.div`
  display: flex;
  justify-content: right;

  button {
    position: relative;
    padding: 16px 32px;

    .btn-spinner {
      position: absolute;
      left: -25px;
      top: -2px;
    }
  }
`;

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  spinnerVisible: boolean;
}

const ConditionsModal: FunctionComponent<IProps> = ({ onClose, onSubmit, spinnerVisible }) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const handleClose = () => {
    if (!!onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleCheckboxChange = (event: FormEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.currentTarget.checked);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!!onSubmit && typeof onSubmit === 'function') {
      onSubmit();
    }
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit} autoComplete="off">
        <StyledTitle>{TERMS_AND_CONDITIONS}</StyledTitle>
        <StyledConditions>
          <SimpleBar style={{ maxHeight: '144px', paddingRight: '35px' }} forceVisible="y" autoHide={false}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum. Lorem ipsum dolor sit amet,
          </SimpleBar>
        </StyledConditions>
        <StyledAcceptWrapper checked={checkboxChecked}>
          <CustomCheckbox
            label={ACCEPT_TERMS_AND_CONDITIONS}
            id="accept-conditions"
            onChange={handleCheckboxChange}
            checked={checkboxChecked}
            required
          />
        </StyledAcceptWrapper>
        <StyledSubmitOrder>
          <Button
            label={
              <span style={{ position: 'relative' }}>
                {SUBMIT_ORDER}
                {spinnerVisible && (
                  <span className="btn-spinner">
                    <Spinner />
                  </span>
                )}
              </span>
            }
            type="submit"
            disabled={!checkboxChecked || spinnerVisible}
            variant={EButtonVariants.SECONDARY}
            withUnderline
            className="order-submit-btn"
          />
        </StyledSubmitOrder>
        <button type="button" className="popup-close" onClick={handleClose}>
          <TimesIcon />
        </button>
      </form>
    </StyledWrapper>
  );
};

export default ConditionsModal;
