import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ShoppingListIcon } from 'components/shared/icons';
import { BROWSE_DATA_FEEDS, EMPTY_ORDER, EMPTY_ORDER_DESCRIPTION } from 'constants/messages';
import { Button } from 'components/shared/button/button';
import { EButtonVariants } from 'components/shared/button/button.entities';

const StyledWrapper = styled.div`
  margin: auto;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-black);

  .empty-cart-icon {
    margin-top: 40px;
  }

  .empty-cart-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    margin-top: 47px;
  }

  .empty-cart-description {
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #404141;
    margin-top: 8px;
  }

  .browse-datafeeds {
    width: 100%;
    background-color: var(--color-black);
    margin-bottom: 100px;
    margin-top: 32px;
  }
`;

interface IProps {
  onNavigate: () => void;
}

const EmptyCart: FunctionComponent<IProps> = ({ onNavigate }) => {
  const handleNavigate = () => {
    if (onNavigate && typeof onNavigate === 'function') {
      onNavigate();
    }
  };

  return (
    <StyledWrapper>
      <div className="empty-cart-icon">
        <ShoppingListIcon width={68} height={67} />
      </div>
      <div className="empty-cart-heading">{EMPTY_ORDER}</div>
      <div className="empty-cart-description">{EMPTY_ORDER_DESCRIPTION}</div>
      <Button
        label={BROWSE_DATA_FEEDS}
        variant={EButtonVariants.PRIMARY}
        className="browse-datafeeds"
        onClick={handleNavigate}
      />
    </StyledWrapper>
  );
};

export default EmptyCart;
