import React, { FunctionComponent } from 'react';

interface IProps {
  width?: number;
  height?: number;
}

export const TimesIcon: FunctionComponent<IProps> = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.99902 2L21.799 21.8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1.99902 21.8L21.799 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
