import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { ErrorIcon } from 'components/shared/icons';

const StyledWrapper = styled.div`
  label {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #c3c4c3;
    margin-right: 13px;
  }

  .input-error {
    margin-top: 26px;
    color: #d233f2;
  }
`;

const StyledInputWrapper = styled.div<{ hasValue: boolean; inline: boolean; hasError: boolean }>`
  position: relative;
  height: 100%;

  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      align-items: center;
    `}

  .text-input {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -17px;
      display: block;
      width: 100%;
      border-bottom: 1px solid #6c6d6d;

      ${({ hasValue }) =>
        hasValue &&
        css`
          border-bottom-color: #c3c4c3;
        `}

      ${({ hasError }) =>
        hasError &&
        css`
          border-bottom-color: #d233f2;
        `}
    }

    ${({ inline }) =>
      inline &&
      css`
        width: 100%;
      `}
  }

  .input-error-icon {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    width: 100%;
    background-color: #121313;
    padding: 16px 45px 16px 16px;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: var(--font-color-white);
    border: 1px solid transparent;
    transition: all 0.3s;

    &:focus {
      outline: none;
      border: 1px solid rgba(135, 136, 136, 1);
      box-shadow: none;

      &::placeholder {
        color: rgba(243, 243, 243, 1);
      }
    }

    &:focus-visible {
      outline: 1px solid var(--focus-outline);
    }

    &::placeholder {
      color: #6c6d6d;
      transition: 0.3s color;
    }
  }
`;

interface IProps {
  label: string;
  id: string;
  value: string;
  onChange: (newValue: string) => void;
  error?: {
    show: boolean;
    message: string;
  };
  type?: string;
  className?: string;
  placeholder?: string;
  inputProps?: Record<string, any>;
  inline?: boolean;
}

export const TextInput: FunctionComponent<IProps> = ({
  type = 'text',
  label = '',
  value,
  id,
  error = {},
  onChange,
  placeholder,
  className = '',
  inputProps = {},
  inline = false,
}) => {
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!!onChange && typeof onChange === 'function') {
      onChange(event.currentTarget.value);
    } else {
      console.error('Component should have a onChange handler');
    }
  };

  const hasError = !!error && !!error.show;

  return (
    <StyledWrapper className={className}>
      <StyledInputWrapper hasValue={!!value} inline={inline} hasError={hasError} className="text-input-wrapper">
        <label htmlFor={id} className="text-input-label">
          {label}
        </label>
        <div className="text-input">
          <input type={type} id={id} placeholder={placeholder} value={value} onChange={handleChange} {...inputProps} />
          {error && error.show && <ErrorIcon className="input-error-icon" />}
        </div>
      </StyledInputWrapper>
      {error && error.show && (
        <div className="input-error" role="alert">
          {error.message}
        </div>
      )}
    </StyledWrapper>
  );
};
