import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { EButtonVariants } from 'components/shared/button/button.entities';

const StyledButton = styled.button<{ variant: EButtonVariants; withUnderline: boolean }>`
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: var(--font-color-white);
  cursor: pointer;
  transition: all 200ms ease-out;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ variant }) =>
    variant === EButtonVariants.PRIMARY &&
    css`
      line-height: 24px;
      padding: 14px 24px;
      font-size: 14px;
      border: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(76.31deg, #7ce3cb 2.01%, #7963b2 99.02%);

      &:hover:not(:disabled) {
        transform: scale(1.02);
      }

      &:disabled {
        opacity: 0.5;
      }
    `}

  ${({ variant }) =>
    variant === EButtonVariants.SECONDARY &&
    css`
      line-height: 14px;
      padding: 14px 16px;
      border: 1px solid #878888;

      &:hover:not(:disabled) {
        transform: scale(1.02);
      }

      &:disabled {
        color: rgba(243, 243, 243, 0.4);
        border: 1px solid #222222;
      }
    `}
  
  ${({ withUnderline }) =>
    withUnderline &&
    css`
      &:after {
        content: '';
        height: 6px;
        width: calc(100% + 2px);
        position: absolute;
        bottom: -5px;
        left: -1px;
        border-bottom: 1px solid #878888;
      }

      &:disabled:after {
        border-bottom: 1px solid #222222;
      }
    `}
  
  ${({ variant }) =>
    variant === EButtonVariants.PRIMARY &&
    css`
      &:after {
        content: '';
        height: 6px;
        width: calc(100% + 2px);
        position: absolute;
        bottom: -5px;
        left: -1px;
        border-bottom: 1px solid #878888;
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(76.31deg, #7ce3cb 2.01%, #7963b2 99.02%);
      }
    `}
`;

interface IProps {
  label: string | ReactNode;
  onClick?: () => void;
  variant?: EButtonVariants;
  type?: 'button' | 'submit';
  className?: string;
  disabled?: boolean;
  textTransform?: 'capitalize' | 'uppercase' | 'none';
  withUnderline?: boolean;
}

export const Button: FunctionComponent<IProps> = ({
  type = 'button',
  variant = EButtonVariants.SECONDARY,
  label,
  className = '',
  textTransform = 'none',
  onClick,
  disabled = false,
  withUnderline = false,
}) => {
  const handleClick = () => {
    if (!!onClick && typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <StyledButton
      type={type}
      variant={variant}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      style={{ textTransform }}
      withUnderline={withUnderline || variant === EButtonVariants.PRIMARY}
    >
      {label}
    </StyledButton>
  );
};
