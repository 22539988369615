import create from 'zustand';
import APIData from 'data/data.json';
import { format } from 'date-fns';
import { calculateChainValueFromHex } from 'utils/utils';
import { toast } from 'react-toastify';
import { COULD_NOT_RETRIEVE_HISTORY_DATA, COULD_NOT_RETRIEVE_VOLATILITY_DATA } from 'constants/messages';
import { Api } from 'utils/api';
import { IBeacon } from 'entities/types/beacon';
import { BigNumber } from 'ethers';

export interface IChartData {
  data: Array<any>;
  loading: boolean;
}

interface IActiveEntityStore {
  activeBeacon: null | IBeacon;
  recentTransactions: Array<any>;
  recentTransactionsLoading: boolean;
  chainValueLoading: boolean;
  historyChart: IChartData;
  volatilityChart: IChartData;
  getTransactions: (beacon: IBeacon) => void;
  getOnChainValue: (beacon: IBeacon) => void;
  getVolatilityChartData: (beaconId: string, chainId: string) => void;
  getHistoryChartData: (beaconId: string, chainId: string) => void;
  getActiveBeacon: (chainName: string, entityId: string) => boolean;
  getActiveDapi: (chainName: string, dapiName: string) => boolean;
  setActiveBeacon: (beacon: IBeacon) => void;
  clearStore: () => void;
}

const initialState: any = {
  activeBeacon: null,
  recentTransactions: [],
  recentTransactionsLoading: false,
  chainValueLoading: false,
  historyChart: {
    data: [[], []],
    loading: false,
  },
  volatilityChart: {
    data: [[], []],
    loading: false,
  },
};

const useActiveEntityStore = create<IActiveEntityStore>((set, get) => ({
  ...initialState,
  getActiveDapi: (chainName: string, dapiName: string) => {
    const dapi = APIData.dapis.find(
      (dapi) =>
        dapi.chain.name.toLowerCase() === chainName.toLowerCase() && dapi.name.toLowerCase() === dapiName.toLowerCase()
    );
    if (dapi) {
      get().setActiveBeacon(dapi);
    }
    return !!dapi;
  },
  getActiveBeacon: (chainName: string, beaconId) => {
    const beacon = APIData.beacons.find(
      (beacon) =>
        beacon.beaconId.toLowerCase() === beaconId.toLowerCase() &&
        beacon.chain.name.toLowerCase() === chainName.toLowerCase()
    );
    if (beacon) {
      get().setActiveBeacon(beacon);
    }
    return !!beacon;
  },
  getTransactions: (beacon: IBeacon) => {
    set({ recentTransactionsLoading: true });
    Api.getTransactions(beacon.chain.id, beacon.beaconId)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          const recentTransactions = data.slice(0, 5).map((item) => {
            const date = new Date(parseInt(item?.parsedLog?.args[2]?.hex, 16) * 1000);

            return {
              chainValue: calculateChainValueFromHex(item?.parsedLog?.args[1]?.hex, beacon.chainShiftValue).toFixed(
                beacon?.chainValueDecimals
              ),
              timestamp: format(date, 'HH:mm | dd MMMM'),
              transactions: {
                url: `${beacon.chain.explorerUrl}/tx/${item.transactionHash}`,
                transactionHash: item.transactionHash.replace('x', '×'),
                logoPath: beacon.chain.logoPath,
              },
            };
          });

          set({ recentTransactions });
        }
      })
      .finally(() => set({ recentTransactionsLoading: false }));
  },
  getOnChainValue: (beacon: IBeacon) => {
    set({ chainValueLoading: true });
    Api.getBeaconChainValue(beacon?.chain?.id, beacon?.beaconId)
      .then(({ data }) => {
        if (!data.error) {
          const chainValue = calculateChainValueFromHex(data.beaconResponse[0].hex, beacon.chainShiftValue);
          set((state) => ({
            activeBeacon: {
              ...(state.activeBeacon as IBeacon),
              onChainValue: chainValue.toFixed(beacon?.chainValueDecimals),
              lastTransactionTimestamp: data.beaconResponse[1] * 1000,
            },
          }));
        }
      })
      .finally(() => {
        set({ chainValueLoading: false });
      });
  },
  getHistoryChartData: (beaconId: string, chainId: string) => {
    set((store) => ({ historyChart: { ...store.historyChart, loading: true } }));

    const to = Date.now();
    const queryFrom = to - 30 * 60 * 60 * 1000;
    const chartFrom = to - 12 * 60 * 60 * 1000;

    // TODO this needs proper typing
    Api.getTransactions(chainId, beaconId)
      .then(({ data }) => {
        const transactions = data
          .map((record: any) => [
            parseInt(record.parsedLog.args[2].hex, 16) * 1_000,
            BigNumber.from(record.parsedLog.args[1].hex).div(BigNumber.from(10).pow(10)).toNumber() / 10 ** 8,
          ])
          .filter(([timestamp]: [number]) => timestamp > queryFrom && timestamp < to);

        // This the quantitisation factor - too low a value here will use too much browser RAM
        const stepSize = 180_000;
        const steps = (to - queryFrom) / stepSize;

        // Interpolate the data into our time range and then fold in the actual data, so we have real data points
        const interpolatedData = [
          ...Array(steps)
            .fill(null)
            .map((_unused, step) => {
              const thisStep = queryFrom + step * stepSize;

              const intermediate =
                transactions.find(([timestamp]: [number]) => timestamp < thisStep) ??
                transactions[transactions.length - 1];

              return [thisStep, intermediate[1]];
            }),
          transactions,
        ]
          .sort()
          .filter(([timestamp]) => timestamp > chartFrom);

        set({
          historyChart: {
            data: interpolatedData,
            loading: false,
          },
        });
      })
      .catch(() => {
        set((store) => ({ historyChart: { ...store.historyChart, loading: false } }));
        toast.error(COULD_NOT_RETRIEVE_HISTORY_DATA);
      });
  },
  getVolatilityChartData: (beaconId: string, chainId: string) => {
    set((store) => ({ volatilityChart: { ...store.volatilityChart, loading: true } }));

    Api.getVolatility(beaconId, chainId)
      .then(({ data }) => {
        set({
          volatilityChart: {
            data,
            loading: false,
          },
        });
      })
      .catch(() => {
        set((store) => ({ volatilityChart: { ...store.volatilityChart, loading: false } }));
        toast.error(COULD_NOT_RETRIEVE_VOLATILITY_DATA);
      });
  },
  setActiveBeacon: (beacon: IBeacon) => {
    if (beacon) {
      set({ activeBeacon: { ...beacon } });

      get().getOnChainValue(beacon);
      get().getTransactions(beacon);
      get().getHistoryChartData(beacon.beaconId, beacon.chain.id);
      return true;
    }
    return false;
  },
  clearStore: () => {
    set(initialState);
  },
}));

export default useActiveEntityStore;
