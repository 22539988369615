import styled from 'styled-components';
import { Table } from 'components/shared/table/table';
import { Button } from 'components/shared/button/button';

export const StyledCartBtn = styled.button`
  display: flex !important;
  align-items: center;
  height: 31px;
  min-width: 73px;
  padding: 3px 16px;
  background-color: var(--font-color-white);
  border-radius: 20px;
  color: #121313;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
  border: none;
  position: relative;

  &:hover,
  &:active,
  &:focus {
    background-color: #b0b1b1;
  }

  & > .count {
    margin-left: 10px;
  }
`;

export const StyledCartEstimatedPrice = styled.div`
  text-align: right;

  .token-price {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #404141;
    text-transform: uppercase;
  }

  .usd-price {
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #6c6d6d;
    text-transform: uppercase;
  }
`;

export const StyledCartDateFeed = styled.div`
  .cart-entity-type {
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    padding: 3px 8px;
    background-color: #dddddd;
    border-radius: 4px;
    margin-right: 8px;
  }

  .datafeed-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #404141;
    margin-bottom: 5px;
    margin-top: 10px;

    .feed {
      width: 32px;
      height: 32px;
      font-size: 20px;
      background-color: transparent;
      color: white;
      box-shadow: none !important;
    }
  }

  .beacon-source {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    color: #404141;
    text-transform: none;

    img {
      margin: 0 8px;
      color: transparent;
    }
  }

  .coverage {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #878888;
  }
`;

export const StyledCartCloseBtn = styled.button`
  position: absolute;
  top: 55px;
  right: 35px;
  border: none;
  background-color: transparent;
  outline: none;
  color: #404141;
  cursor: pointer;
  z-index: 1001;
  padding: 0 10px;
`;

export const StyledCartTable = styled(Table)`
  .table {
    background-color: transparent;

    tbody {
      tr:hover {
        background-color: transparent;
      }

      td {
        padding: 15px 5px;
      }
    }

    .column-label {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #222222;
    }

    thead tr {
      height: 34px;
    }
  }

  .pagination-container {
    display: none;
  }
`;

export const StyledCartDeleteBtn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: #121313;
`;

export const StyledCartTotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 11px;
  text-align: center;

  .total-label {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-left: 54px;
  }

  .total-price {
    padding-right: 25px;
  }

  .total-token-price {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: #121313;
  }

  .total-usd-price {
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #6c6d6d;
  }
`;
export const StyledCartPrimaryBtn = styled(Button)`
  width: 540px;
  display: block;
  color: white;
  background-color: #222222;
  margin: 0 auto 30px auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

export const StyledCartActionsContainer = styled.div`
  display: flex;
`;

export const StyledCartPopoverContent = styled.div`
  width: 800px;
  position: fixed;
  top: 0;
  right: 0;
  left: unset;
  height: 100vh;
  background-color: #f3f3f3;
  z-index: 1000;
  color: #404141;
  padding: 150px 80px 16px 80px;
  transition: right 100ms, opacity 300ms;
  overflow: hidden;

  .simplebar-scrollbar:before {
    background: gray;
  }

  .delete-column {
    vertical-align: top !important;
    padding-top: 30px !important;
  }

  .cart-currency-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }

  .popover-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .popover-header-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
  }

  .popover-header-mainnet {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222222;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;

    & img {
      margin-right: 5px;
    }
  }

  .popover-description {
    width: 372px;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
  }

  .datafeed-name {
    display: flex;
    align-items: center;
  }

  & > img {
    margin-right: 5px;
    color: transparent;
  }

  .free-trial-text {
    text-align: center;
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 1440px) {
    width: 540px;
    padding: 65px 36px 0 36px;

    ${StyledCartCloseBtn} {
      right: 20px;
      top: 36px;
    }

    ${StyledCartPrimaryBtn} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 540px) {
    width: 100%;
    padding: 65px 25px 0 25px;

    ${StyledCartPrimaryBtn} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 510px) {
    .popover-description {
      width: 100%;
    }
  }

  @media only screen and (max-width: 405px) {
    padding-left: 15px;
    padding-right: 15px;

    table {
      td,
      th {
        padding-left: 0 !important;
      }

      ${StyledCartDeleteBtn} {
        padding-left: 0;
      }
    }
  }
`;
