import React, { useEffect } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { PageEntity } from 'components/page-entity/page-entity';
import { ERoutePaths } from 'entities/enums/routes';
import { toast } from 'react-toastify';
import Layout from './components/layout/layout';
import BeaconsPage from './pages/beacons/beacons';
import DApiPage from './pages/d-api/d-api';
import OrderPage from './pages/order/order';

const App = () => {
  const clearToasts = () => {
    if (document && document.visibilityState === 'hidden') {
      toast.dismiss();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!document && document.addEventListener('visibilitychange', clearToasts);

    return () => {
      // eslint-disable-next-line no-unused-expressions
      !!document && document.removeEventListener('visibilitychange', clearToasts);
    };
  }, []);

  return (
    <Routes>
      <Route path={ERoutePaths.HOME} element={<Layout />}>
        <Route path={ERoutePaths.BEACONS} element={<Outlet />}>
          <Route path=":chainName/:beaconId" element={<PageEntity type="beacon" />} />
          <Route index element={<BeaconsPage />} />
        </Route>
        <Route path={ERoutePaths.DAPIS} element={<Outlet />}>
          <Route path=":chainName/:dapiName" element={<PageEntity type="dapi" />} />
          <Route index element={<DApiPage />} />
        </Route>
        <Route path={ERoutePaths.ORDER} element={<OrderPage />} />
        <Route index element={<Navigate to={ERoutePaths.DAPIS} replace />} />
      </Route>
      <Route path="*" element={<Navigate to={ERoutePaths.DAPIS} replace />} />
    </Routes>
  );
};

export default App;
