import React, { FunctionComponent, memo, ReactNode, useState } from 'react';
import { ChevronDown, TimesIcon } from 'components/shared/icons/';
import styled, { css } from 'styled-components';
import { Popover, PopoverPlacement, PopoverTriggerType } from '@idui/react-popover';

const StyledPopover = styled(Popover)`
  background-color: #121313;
  padding: 10px 14px 10px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(16, 18, 17, 0.9);
  border: 1px solid #222222;

  .idui-popover__arrow {
    background-color: #121313 !important;
    border: none !important;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid #222222 !important;
    border-left: 1px solid #222222 !important;
  }

  .idui-popover__content {
    min-width: unset;
    padding: 2px;
  }
`;

const StyledFilterBtn = styled.span<{ isOpen: boolean; active: boolean }>`
  display: block;
  position: relative;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 48px 6px 12px;
  border-bottom: 1px solid #404141;
  border-top: 1px solid #404141;
  color: #6c6d6d;
  margin: 0 15px 0 10px;
  cursor: pointer;
  transition: all 200ms;
  text-align: right;
  white-space: pre;

  .filter-btn-count {
    display: inline-flex;
    min-width: 24px;
    justify-content: right;
  }

  svg.arrow {
    position: absolute;
    right: 7px;
    top: 50%;
    transition: all 200ms;
    transform: translateY(-50%);
  }

  &:hover {
    color: #c3c4c3;
    border-color: #878888;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: #c3c4c3;
      border-color: #878888;

      svg.arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    `}

  ${({ active }) =>
    active &&
    css`
      color: #c3c4c3;
      border-color: #878888;
    `}
`;

const StyledFilterWrapper = styled.div`
  position: relative;

  .filter-clear-btn {
    position: absolute;
    background-color: transparent;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: inherit;
  }
`;

interface IProps {
  active: boolean;
  popoverContent: ReactNode;
  count?: number | string;
  btnLabel?: string;
  onClear: () => void;
}

export const GenericFilter: FunctionComponent<IProps> = memo(
  ({ active, btnLabel = '', count = '', popoverContent, onClear }) => {
    const [filterOpen, setFilterOpen] = useState(false);

    const handleClearFilter = (event: any) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      if (onClear && typeof onClear === 'function') {
        onClear();
      }
    };

    return (
      <StyledFilterWrapper>
        <StyledPopover
          isOpen={filterOpen}
          trigger={PopoverTriggerType.click}
          placement={PopoverPlacement.bottom}
          onChangeOpen={(value) => setFilterOpen(value)}
          content={popoverContent}
          lazy={false}
        >
          <StyledFilterBtn
            className="filter-open-btn"
            isOpen={filterOpen}
            active={active}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                setFilterOpen(true);
              }
            }}
            tabIndex={0}
          >
            <span className="filter-btn-count">{count || ''}</span> {btnLabel}{' '}
            {!active && <ChevronDown className="arrow" />}
          </StyledFilterBtn>
        </StyledPopover>
        {active && (
          <button type="button" className="filter-clear-btn" onClickCapture={handleClearFilter}>
            <TimesIcon width={10} height={10} />
          </button>
        )}
      </StyledFilterWrapper>
    );
  }
);
