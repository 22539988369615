import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  DISCORD_SUCCESS_DESCRIPTION,
  EMAIL_SUCCESS_DESCRIPTION,
  RETURN_HOME,
  SUBMIT_ORDER_THANKS,
  TELEGRAM_SUCCESS_DESCRIPTION,
} from 'constants/messages';
import { OrderSuccessIcon } from 'components/shared/icons';
import { EContactOption } from 'entities/enums/contact-options';
import { ERoutePaths } from 'entities/enums/routes';

const StyledWrapper = styled.div`
  padding-top: 40px;

  .submit-success-heading {
    font-size: 27px;
    line-height: 44px;
    text-align: center;
    color: #c3c4c3;
    margin-top: 30px;
    margin-bottom: 24px;
  }

  .submit-success-description {
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #c3c4c3;
    margin-bottom: 65px;
  }

  .action-container,
  .icon-container {
    text-align: center;
  }

  .return-home-btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: rgba(195, 196, 195, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    &:hover {
      color: rgba(243, 243, 243, 1);
    }
  }
`;

interface IProps {
  contactOption: string;
}

const OrderSubmitted: FunctionComponent<IProps> = ({ contactOption }) => {
  const navigate = useNavigate();
  const getDescription = () => {
    switch (contactOption) {
      case EContactOption.DISCORD:
        return DISCORD_SUCCESS_DESCRIPTION;
      case EContactOption.TELEGRAM:
        return TELEGRAM_SUCCESS_DESCRIPTION;
      case EContactOption.EMAIL:
        return EMAIL_SUCCESS_DESCRIPTION;
      default:
        return '';
    }
  };

  return (
    <StyledWrapper>
      <div className="icon-container">
        <OrderSuccessIcon />
      </div>
      <div className="submit-success-heading">{SUBMIT_ORDER_THANKS}</div>
      <div className="submit-success-description">{getDescription()}</div>
      <div className="action-container">
        <button type="button" className="return-home-btn" onClick={() => navigate(ERoutePaths.HOME)}>
          {RETURN_HOME}
        </button>{' '}
      </div>
    </StyledWrapper>
  );
};

export default OrderSubmitted;
