export const selectStyles = {
  container: (provided: any) => ({ ...provided, width: '220px', margin: '0 auto' }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#f3f3f3' : '#878888',
    padding: '7px 30px',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '150%',
    backgroundColor: '#121313',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#333131',
    },
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '8px',
      transform: 'translateY(-50%)',
      content: '""',
      width: 12,
      height: 12,
      borderRadius: '50%',
      border: '1px solid #404141',
      backgroundColor: state.isSelected ? 'white' : 'transparent',
    },
  }),
  menuList: (provided: any) => ({ ...provided, padding: 0 }),
  control: () => ({
    display: 'flex',
    backgroundColor: 'transparent',
    color: 'var(--font-color-white)',
    padding: '6px 0 6px 20px',
    border: '1px solid #404141',
    cursor: 'pointer',
    height: '45px',
    fontSize: '18px',
    lineHeight: '29px',
  }),
  valueContainer: (provided: any) => ({ ...provided, padding: 0 }),
  indicatorSeparator: () => ({ display: 'none' }),
  singleValue: (provided: any) => ({ ...provided, color: '#C3C4C3' }),
};
