import axios from 'axios';
import { contactOptionSchema } from 'entities/enums/contact-options';
import { z } from 'zod';

export const evmBeaconIdSchema = z.string().regex(/^0x[a-fA-F0-9]{64}$/);

const contactUsPayloadSchema = z.object({
  contactOption: contactOptionSchema,
  userName: z.string(),
  groupName: z.string().optional(),
  token: z.string().nullable(),
  order: z.object({
    chainId: z.string(),
    chainName: z.string(),
    items: z.array(
      z.object({
        beaconId: evmBeaconIdSchema,
        beaconName: z.string(),
        coverage: z.number(),
        months: z.number(),
        fee: z.number(),
      })
    ),
  }),
});

export type IContactUsPayload = z.infer<typeof contactUsPayloadSchema>;

export class Api {
  public static contactUs(payload: IContactUsPayload) {
    return axios.post('https://contactus.api3data.link/contactus', payload);
  }

  public static getBeaconChainValue(chainId: string, beaconId: string) {
    return axios.get(`https://explorer-api.api3.org/beacons/on_chain_value?chainId=${chainId}&dataFeedId=${beaconId}`);
  }

  public static getTransactions(chainId: string, beaconId: string) {
    return axios.get(
      `https://explorer-api.api3.org/beacons/last_transactions?chainId=${chainId}&beaconId=${beaconId}&transactionCountLimit=40`
    );
  }

  public static getVolatility(beaconId: string, chainId: string) {
    return axios.get(`https://explorer-api.api3.org/beacons/volatility?chainId=${chainId}&beaconId=${beaconId}`);
  }
}
