export enum _ETransactionColumnClassNames {
  ON_CHAIN_VALUE = 'on-chain-column',
  TIMESTAMP = 'timestamp-column',
  TRANSACTION = 'transaction-column',
  COPY = 'copy-column',
}

export enum EEntitiesTableColumnClassNames {
  DATAFEED = 'datafeed-entity-column',
  SOURCE = 'source-entity-column',
  NETWORK = 'network-entity-column',
  ON_CHAIN_VALUE = 'on-chain-entity-column',
  CATEGORY = 'category-entity-column',
}
