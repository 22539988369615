import React, { FunctionComponent } from 'react';

interface IProps {
  width?: number;
  height?: number;
}

export const ShoppingListIcon: FunctionComponent<IProps> = ({ width = 21, height = 22 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4894 17.2739L17.4894 1.34984M17.4894 1.34984L14.7289 4.1104M17.4894 1.34984L20.25 4.1104"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5941 1.3501H2.75C1.64543 1.3501 0.75 2.24553 0.75 3.3501V18.6522C0.75 19.7568 1.64543 20.6522 2.75 20.6522H15.4881C16.5927 20.6522 17.4881 19.7568 17.4881 18.6522V17.0812"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.85181 7.57104L4.63487 7.57105" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.12866 7.57104H13.6727" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.85181 11.7156L4.63487 11.7156" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.12866 11.7156H13.6727" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

      <path d="M3.85181 15.8601L4.63487 15.8601" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.12866 15.8601H13.6727" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
