import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { BurgerIcon, TimesIcon } from 'components/shared/icons';
import { headerLinks } from 'constants/header-links';
import { Button } from 'components/shared/button/button';
import { REQUEST_DATA } from 'constants/messages';
import { EButtonVariants } from 'components/shared/button/button.entities';
import { useNavigate } from 'react-router-dom';
import { REQUEST_DATA_LINK } from 'constants/constants';

const StyledToggleBtn = styled.button<{ isOpen: boolean }>`
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 26px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1003;
    `}
`;

const StyledFixedMenu = styled.div`
  width: 360px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  background-color: rgba(243, 243, 243, 1);
  z-index: 1002;
  justify-content: space-between;
  overflow: hidden;
  transition: right 0.2s, opacity 0.5s;

  .mobile-navigation {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
  }

  .request-data-mobile {
    display: block;
    width: 100%;

    button {
      width: 100%;
      background-color: var(--color-black);
      color: var(--font-color-white);
    }
  }
`;

const StyledButton = styled.button`
  font-weight: 600;
  font-size: 27px;
  line-height: 35px;
  padding: 16px 0 0 40px;
  text-decoration: none;
  color: rgba(3, 3, 3, 1);
  border: none;
  text-align: left;
  background-color: transparent;
`;

const sidebarTransitionStyles: Record<string, any> = {
  entering: { right: '-100%', opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { right: '-100%', opacity: 0 },
};

interface IProps {
  onOpen: () => void;
}

const MobileMenu: FunctionComponent<IProps> = ({ onOpen }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [navigationPath, setNavigationPath] = useState({});

  const toggleMobileMenu = () => {
    if (!menuOpen && onOpen && typeof onOpen === 'function') {
      onOpen();
    }
    setMenuOpen(!menuOpen);
  };

  const navigateToPath = (path: string) => {
    setMenuOpen(false);
    setNavigationPath(path);
  };

  const handleNavigation = () => {
    if (navigationPath) {
      navigate(navigationPath);
      setNavigationPath('');
    }
  };

  return (
    <>
      <StyledToggleBtn type="button" onClick={toggleMobileMenu} isOpen={menuOpen}>
        {menuOpen ? <TimesIcon /> : <BurgerIcon />}
      </StyledToggleBtn>
      <CSSTransition in={menuOpen} timeout={200} onExited={handleNavigation}>
        {(state) => (
          <StyledFixedMenu style={{ ...sidebarTransitionStyles[state] }}>
            <div className="mobile-navigation">
              {headerLinks.map((link) => (
                <StyledButton type="button" onClick={() => navigateToPath(link.path)}>
                  {link.label}
                </StyledButton>
              ))}
            </div>
            <a href={REQUEST_DATA_LINK} target="_blank" rel="noreferrer" className="request-data-mobile">
              <Button label={REQUEST_DATA} variant={EButtonVariants.PRIMARY} />
            </a>
          </StyledFixedMenu>
        )}
      </CSSTransition>
    </>
  );
};

export default MobileMenu;
