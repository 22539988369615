import React, { FunctionComponent, useMemo, useRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

interface IProps {
  data: Array<any>;
  seriesLabel: string;
  yAxisName?: string;
}

export const CustomLineChart: FunctionComponent<IProps> = ({ data = [], seriesLabel = '', yAxisName }) => {
  const chartInstance = useRef<any>(null);

  echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

  const memoizedOption = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        top: yAxisName ? '35px' : '20px',
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'time',
        splitLine: {
          show: true,
        },
        minorSplitLine: {
          show: false,
        },
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        scale: 'true',
        name: yAxisName,
        nameLocation: 'end',
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          name: seriesLabel,
          type: 'line',
          step: 'start',
          data,
          color: 'rgba(124, 227, 203, 1)',
        },
      ],
      backgroundColor: '#121414',
      useUTCTime: true,
    };
  }, [data]);

  if (chartInstance.current?.resize && typeof chartInstance.current.resize === 'function') {
    chartInstance.current.resize();
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={memoizedOption}
      lazyUpdate
      theme="dark"
      onChartReady={(instance) => {
        // eslint-disable-next-line functional/immutable-data
        chartInstance.current = instance;
      }}
    />
  );
};
