import styled, { css } from 'styled-components';
import { Tabs } from 'react-tabs';
import { Button, Table } from 'components/shared';
import { MEDIUM_WIDTH_ENTITY_BREAKPOINT, MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT } from 'constants/styles';
import { ETransactionCellClassNames } from 'entities/enums/cell-class-names';

export const StyledEntityInfo = styled.div`
  width: calc(100% - 800px + 134px);
  margin-top: 10px;

  .entity-info-content {
    border-right: 1px solid #404141;
    padding-right: 80px;
  }

  @media only screen and (max-width: 1456px) {
    width: 731px;
  }

  @media only screen and (max-width: 1439px) {
    width: 100%;
  }
`;

export const StyledEntityIDDetails = styled.div`
  display: flex;
  color: #f3f3f3;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  white-space: nowrap;

  .entity-chain-logo {
    display: flex;
    align-items: center;
  }

  .copy-container {
    display: inline-block;
    position: relative;
    margin-left: 32px;
    background-color: rgba(34, 34, 34, 1);
    padding: 6px 45px 6px 18px;
    border-radius: 6px;
    cursor: pointer;

    .copy-btn {
      position: absolute;
      top: 8px;
      right: 10px;
      background-color: transparent;
      border: none;
    }

    .entity-id {
      margin-left: 5px;
      color: var(--color-emerald);
      text-transform: lowercase;
    }
  }

  img {
    color: transparent;
    margin-right: 5px;
    max-width: 30px;
  }
`;

export const StyledEntityCurrencyAndStatus = styled.div<{ mobileSubscribe: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 18px;

  .beacon-name {
    display: flex;
    align-items: center;
  }

  .status-icon {
    margin-right: 10px;
  }

  .beacon-name-label {
    font-weight: 400;
    font-size: 50px;
    line-height: 120%;
    text-transform: uppercase;
  }

  ${({ mobileSubscribe }) =>
    mobileSubscribe &&
    css`
      @media only screen and (max-width: 860px) {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: initial;
        .mobile-subscribe-btn {
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
    `}
`;

export const StyledEntityPoweredBy = styled.div`
  width: 50%;
  margin-top: -15px;

  .powered-by-label {
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
  }

  .provider {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 18px;

    img {
      margin-right: 10px;
    }
  }

  .provider-link {
    margin-left: 14px;
  }

  .provider-description {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #6c6d6d;
    padding-right: 40px;
  }
`;

export const StyledEntityAdditionalInfo = styled.div`
  display: flex;
  margin-top: 38px;

  @media only screen and (max-width: 670px) {
    flex-direction: column;

    .provider-description {
      padding-right: 0;
    }

    ${StyledEntityPoweredBy} {
      width: 100%;
    }

    .on-chain-container {
      width: 100%;
      margin: 40px 0 20px 0;
    }

    .on-chain-content {
      margin-top: 0;
    }
  }
`;

export const StyledEntityTabs = styled(Tabs)`
  margin-top: 96px;

  .react-tabs__tab-list {
    list-style: none;
    display: flex;
    border-bottom: 1px solid #404141;
    padding: 0;
  }

  .react-tabs__tab {
    padding: 6px 42px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6c6d6d;
    transition: 0.3s color;

    &:hover {
      color: var(--font-color-white);
    }

    &.react-tabs__tab--selected {
      color: var(--font-color-white);
      border-bottom: 1px solid white;
    }
  }
`;

export const StyledEntityTransactions = styled.div`
  margin-top: 96px;
  margin-bottom: 24px;

  .recent-transactions-title {
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
  }

  .recent-transactions-table {
    width: 100%;
    text-align: left;
    margin-top: 24px;
    border-collapse: collapse;

    tbody tr {
      border-bottom: 1px solid white;
    }

    tbody td {
      font-weight: 300;
      font-size: 12px;
      line-height: 160%;
      padding: 10px 16px 14px 16px;
    }

    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding: 6px 16px;
    }
  }
`;

export const StyledEntityTransactionsTable = styled(Table)`
  tbody tr:first-child {
    border-top: none !important;
  }

  tr {
    &:hover {
      background-color: transparent !important;
      cursor: auto;
    }

    &.table-empty-row {
      border-top: 1px solid #404141;
    }

    .column-label {
      cursor: auto !important;
    }
  }

  .pagination-container {
    display: none;
  }

  .${ETransactionCellClassNames.TIMESTAMP} {
    white-space: nowrap;
  }

  .${ETransactionCellClassNames.TRANSACTION} {
    max-width: 400px;
    line-break: anywhere;
    text-transform: none;
  }
`;

export const StyledEntityWrapper = styled.div`
  padding-top: 20px;

  @media only screen and (max-width: 1850px) {
    .subscribe-section {
      max-width: 534px;
      padding-right: 134px;
      padding-left: 30px;
    }

    ${StyledEntityInfo} {
      width: calc(100% - 534px + 134px);

      .entity-info-content {
        padding-right: 40px;
      }
    }
  }

  @media only screen and (max-width: ${MEDIUM_WIDTH_ENTITY_BREAKPOINT}px) {
    .subscribe-section {
      padding-right: 60px;
      width: 400px;
    }

    .entity-info {
      width: calc(100% - 400px + 60px);

      .entity-info-content {
        padding-right: 40px;
      }
    }
  }

  @media only screen and (max-width: ${MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT}px) {
    .entity-info {
      width: 100%;

      .entity-info-content {
        border: none;
        padding-right: 0;
      }
    }

    .on-chain-container {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 510px) {
    .subscribe-section {
      padding: 35px 25px 0 25px;
      display: flex;
      flex-direction: column;
    }

    ${StyledEntityIDDetails} {
      flex-direction: column;
      align-items: initial;

      .copy-container {
        margin-left: 0;
        margin-top: 2px;
      }
    }

    .beacon-name-label {
      font-weight: 400;
      font-size: 27px;
      line-height: 120%;
      color: #f3f3f3;
    }

    .mobile-subscribe-btn {
      margin: 0 auto;
      margin-top: 32px;
    }

    .powered-by-label {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
    }

    .provider {
      margin-top: 5px;
    }

    ${StyledEntityTransactions} {
      margin-top: 64px;

      table {
        th:first-of-type {
          min-width: 75px;
        }

        th,
        td {
          padding: 4px 6px;
        }
      }

      .copy-btn {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media only screen and (max-height: 850px) {
    .subscribe-coverage-label {
      margin-top: 35px;
    }

    .subscribe-monthly-fee-label {
      margin-top: 14px;
      font-size: 16px;
    }

    .subscribe-order-container {
      margin-top: 0;
    }

    .subscribe-section {
      padding: 46px 25px 0 25px;
      display: flex;
      flex-direction: column;
    }

    .subscribe-section-title {
      font-size: 18px;
    }
  }

  @media only screen and (max-height: 720px) {
    .subscribe-coverage-label {
      margin-top: 25px;
    }

    .subscribe-monthly-fee-label {
      margin-top: 15px;
      font-size: 16px;
    }

    .subscribe-section {
      padding: 35px 25px 0 25px;
      display: flex;
      flex-direction: column;
    }

    .subscribe-section-title {
      font-size: 18px;
      margin-top: -13px;
    }

    .subscribe-summary {
      margin-top: 40px;
    }

    .subscribe-order-container {
      margin-top: 5px;
    }
  }
`;

export const StyledEntityInfoContent = styled.div`
  border-right: 1px solid #404141;
  padding-right: 80px;
`;

export const StyledMobileSubscribeBtn = styled(Button)`
  width: 312px;
  text-align: center;
  margin-left: auto;
`;
