import create from 'zustand';
import { persist } from 'zustand/middleware';
import { toast } from 'react-toastify';
import { DATAFEED_ALREADY_IN_ORDER, SUBSCRIPTION_ADDED_TO_ORDER } from 'constants/messages';
import { IBeacon } from 'entities/types/beacon';

export interface IBuyOption {
  coverage: number;
  fee: number;
  months: number;
}

export interface ICartItem {
  beacon: IBeacon;
  options: IBuyOption;
}

interface ICartStore {
  chain: Record<string, any> | null;
  cartItems: Array<ICartItem>;
  addCartItem: (beacon: IBeacon, buyOption: IBuyOption) => void;
  removeCartItem: (beaconId: string, beaconType: string) => void;
  clearStore: () => void;
}

const initialStore = { chain: null, cartItems: [] };

export const useCartStore = create<ICartStore>()(
  persist((set, get) => ({
    ...initialStore,
    addCartItem: (beacon, buyOption) => {
      const { chain, cartItems } = get();
      if (
        (!chain || chain?.id === beacon.chain.id) &&
        !cartItems.find((item) => item.beacon.beaconId === beacon.beaconId && item.beacon.type === beacon.type)
      ) {
        set((state) => ({
          chain: !chain ? beacon?.chain : state.chain,
          cartItems: [...state.cartItems, { beacon, options: buyOption }],
        }));
        toast.info(SUBSCRIPTION_ADDED_TO_ORDER, { icon: false, toastId: SUBSCRIPTION_ADDED_TO_ORDER });
      } else if (!!chain && chain?.id !== beacon.chain.id) {
        console.error('Only subscriptions on the same chain are allowed!', {
          toastId: 'Only subscriptions on the same chain are allowed!',
        });
      } else if (
        cartItems.find((item) => item.beacon.beaconId === beacon.beaconId && item.beacon.type === beacon.type)
      ) {
        toast.error(DATAFEED_ALREADY_IN_ORDER, { toastId: DATAFEED_ALREADY_IN_ORDER });
      }
    },
    removeCartItem: (beaconId, beaconType) => {
      console.log(1, { beaconId, beaconType });
      set((store) => {
        const newItems = store.cartItems.filter(
          (item) => !(item.beacon.beaconId === beaconId && item.beacon.type === beaconType)
        );
        return { cartItems: newItems, chain: newItems.length > 0 ? store.chain : null };
      });
    },
    clearStore: () => {
      set({ ...initialStore });
    },
  }))
);
