import React from 'react';

export const ExternalLinkIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9995 11.001H0.999481V1.00098" stroke="#6C6D6D" />
      <path d="M3.84457 8.1579L10.5232 1.47754" stroke="#6C6D6D" />
      <path d="M6.11246 1.47656H10.5229V5.88894" stroke="#6C6D6D" />
    </svg>
  );
};
