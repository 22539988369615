import React, { FunctionComponent } from 'react';

interface IProps {
  width: number;
  height: number;
  className?: string;
}

export const ArrowUpIcon: FunctionComponent<IProps> = ({ width = 8, height = 7, className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 0.500977L7.4641 6.50098L0.535897 6.50098L4 0.500977Z" fill="currentColor" />
    </svg>
  );
};
