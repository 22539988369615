import { ICartItem } from 'stores/cart/cart.store';
import { IFilterOptions } from 'entities/types/filter';
import { EContactOption } from 'entities/enums/contact-options';

export const getExplorerUrl = (chainName: string) => {
  switch (chainName.toLowerCase()) {
    case 'bsc':
      return 'https://bscscan.com';
    case 'rsk':
      return 'https://explorer.rsk.co';
    case 'ropsten':
      return 'https://ropsten.etherscan.io';
    case 'polygon':
      return 'https://polygonscan.com';
    case 'avalanche':
      return 'https://snowtrace.io';
    default:
      return '';
  }
};

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function calculateUSDPrice(items: Array<ICartItem>) {
  return items.reduce((acc, item) => {
    // eslint-disable-next-line no-param-reassign
    acc += item.options.fee * item.options.months;
    return acc;
  }, 0);
}

export const calculateChainValueFromHex = (hex: string, shiftValue: string) => {
  return parseInt(hex, 16) / parseInt(shiftValue, 10);
};

export function sortFilterOptions(filterOptions: IFilterOptions): IFilterOptions {
  return {
    categories: filterOptions.categories.sort((catA, catB) => catA.localeCompare(catB)),
    chains: filterOptions.chains.sort((chainA, chainB) => chainA.fullName.localeCompare(chainB.fullName)),
    sources: filterOptions.sources.sort((sourceA, sourceB) => sourceA.name.localeCompare(sourceB.name)),
  };
}

function startsWithNumber(str: string) {
  return /^\d/.test(str);
}

export function validateUserId(userId: string, activeOption: EContactOption) {
  if (userId) {
    switch (activeOption) {
      case EContactOption.TELEGRAM: {
        const goodRegex = /^[A-Za-z]{2,}[_-]?[A-Za-z0-9]{2,}$/;
        const lengthInvalid = userId.length < 5 || userId.length > 32;

        if (startsWithNumber(userId)) {
          return { show: true, message: 'Username cannot start with numbers' };
        }

        if (!goodRegex.test(userId) && !lengthInvalid) {
          return {
            show: true,
            message: 'Your username can only include a-z, 0-9, and underscores!',
          };
        }
        if (lengthInvalid) {
          return {
            show: true,
            message: 'Your username must be between 5 and 32 characters long!',
          };
        }
        break;
      }

      case EContactOption.DISCORD: {
        const validationRegex = /^((?!(discordtag|everyone|here)#)((?!@|#|:|```).+)#\d{4})/;
        const lengthInvalid = userId.length < 2 || userId.length > 32;

        if (!validationRegex.test(userId) && !lengthInvalid) {
          return { show: true, message: 'Usernames cannot include @, #, :, ```, discord, everyone, here!' };
        }
        if (lengthInvalid) {
          return {
            show: true,
            message: 'Your username must be between 2 and 32 characters long!',
          };
        }
        break;
      }
      case EContactOption.EMAIL: {
        const rfc2822Regex =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        const lengthInvalid = userId.length < 2 || userId.length > 32;

        if (!rfc2822Regex.test(userId) && !lengthInvalid) {
          return { show: true, message: 'You must enter a valid email!' };
        }
        if (lengthInvalid) {
          return { show: true, message: 'Your email must be between 2 and 32 characters long!' };
        }
        break;
      }
      default:
        break;
    }
  }
  return { show: false, message: '' };
}
