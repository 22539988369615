import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { footerLinks } from 'constants/footer-links';
import { containerStyles, FOOTER_HEIGHT_BIG } from 'constants/styles';
import BackgroundImg from '../../../assets/images/footer-bg.webp';

const StyledWrapper = styled.footer<{ isContainer: boolean }>`
  ${({ isContainer }) => isContainer && containerStyles}
  height: ${FOOTER_HEIGHT_BIG}px;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
`;

const StyledBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 140px;
  width: 100%;
  z-index: -1;

  background-image: url(${BackgroundImg});
  background-position: left bottom;
  background-size: 908px 100%;
  background-repeat: no-repeat;
`;

const StyledFooterNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > .footer-link {
    line-height: 21px;
    font-size: 14px;
    font-weight: 300;
    padding: 0 40px;
    border-right: 1px solid var(--color-neutral-gray);
    cursor: pointer;
    text-decoration: none;
    color: var(--font-color-white);
    transition: color 100ms ease-in;

    &:first-of-type {
      padding-left: 0 !important;
    }

    &:hover {
      color: #878888;
    }

    &:last-child {
      border-right: none;
    }
  }
`;

interface IProps {
  isContainer?: boolean;
}

const Footer: FunctionComponent<IProps> = ({ isContainer = true }) => {
  return (
    <StyledWrapper isContainer={isContainer}>
      <StyledBackground />
      <StyledFooterNavigation>
        {footerLinks.map((link) => (
          <a
            key={link.path}
            className="footer-link"
            href={link.path}
            aria-label={`Visit ${link.label} web`}
            target="_blank"
            rel="noreferrer"
          >
            {link.label}
          </a>
        ))}
      </StyledFooterNavigation>
    </StyledWrapper>
  );
};

export default Footer;
