import React from 'react';

export const OrderSuccessIcon = () => {
  return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.8998 124.999H93.1003C97.1042 124.999 100.35 121.754 100.35 117.75V62.2732C100.35 58.2693 97.1042 55.0234 93.1003 55.0234H46.8998C42.8958 55.0234 39.65 58.2693 39.65 62.2732V117.75C39.65 121.754 42.8959 124.999 46.8998 124.999Z"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8971 77.5781L53.7368 77.5781"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.7831 77.5781H86.5147"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8971 92.6016L53.7368 92.6016"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.7831 92.6016H86.5147"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8971 107.627L53.7368 107.627"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.7831 107.627H86.5147"
        stroke="#F3F3F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.4141 69.8424C82.064 69.8424 91.5086 60.4012 91.5086 48.7543C91.5086 37.1075 82.064 27.6662 70.4141 27.6662C58.7641 27.6662 49.3196 37.1075 49.3196 48.7543C49.3196 60.4012 58.7641 69.8424 70.4141 69.8424Z"
        fill="#030303"
        stroke="#030303"
        strokeWidth="1.67143"
      />
      <path
        d="M86.8359 48.754C86.8359 57.8202 79.4839 65.1703 70.4141 65.1703C61.3444 65.1703 53.9923 57.8202 53.9923 48.754C53.9923 39.6878 61.3444 32.3377 70.4141 32.3377C79.4839 32.3377 86.8359 39.6878 86.8359 48.754Z"
        fill="#030303"
        stroke="#7CE3CB"
        strokeWidth="1.67143"
      />
      <path d="M62.438 50.279L67.2061 55.2152L79.3151 42.6914" stroke="#7CE3CB" strokeWidth="1.7942" />
      <path d="M57.5827 31.4251L50.1553 24" stroke="#7CE3CB" strokeWidth="1.5" />
      <path d="M84.7461 31.4251L92.1735 24" stroke="#7CE3CB" strokeWidth="1.5" />
      <path d="M71.1644 25.6079L71.1644 15" stroke="#7CE3CB" strokeWidth="1.5" />
    </svg>
  );
};
