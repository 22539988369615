import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ADD_TO_ORDER,
  COVERED_UP_TO,
  MONTHLY_FEE,
  NO_VALUE,
  SUBSCRIBE_DESCRIPTION,
  SUBSCRIBE_HEADER,
  TOTAL,
  TRIAL_UNTIL,
  USD,
} from 'constants/messages';
import { NumericInput } from 'components/shared/numeric-input/numeric-input';
import { CustomSlider } from 'components/shared/custom-slider/custom-slider';
import { Button } from 'components/shared/button/button';
import { EButtonVariants } from 'components/shared/button/button.entities';
import { HEADER_HEIGHT_BIG } from 'constants/styles';
import { ErrorIcon, TimesIcon } from 'components/shared/icons';

const StyledWrapper = styled.div<{ mobile: boolean; mobileVisible: boolean }>`
  height: 100vh;
  width: 800px;
  position: fixed;
  top: ${HEADER_HEIGHT_BIG}px;
  right: 0;
  padding: 65px 235px 40px 100px;
  background-color: var(--color-black);
  z-index: 3;
  transition: right 0.25s, opacity 0.25s;

  ${({ mobile, mobileVisible }) =>
    mobile &&
    !mobileVisible &&
    css`
      right: -1500px;
      opacity: 0;
    `}

  ${({ mobile, mobileVisible }) =>
    mobile &&
    mobileVisible &&
    css`
      width: 100% !important;
      max-width: 100% !important;
      opacity: 1;
    `}
  
  .subscribe-section-container {
    position: relative;
    max-width: 464px;
    margin: 0 auto;

    .subscribe-close-btn {
      position: absolute;
      right: 0;
      top: -23px;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;
      color: #f3f3f3;
    }
  }
`;

const StyledTitle = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  color: #f3f3f3;
`;

const StyledDescription = styled.div`
  color: #6c6d6d;
  max-width: 464px;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;

const StyledSectionName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin-top: 45px;
  margin-bottom: 16px;
`;

const StyledCoverage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c3c4c3;
  margin-top: 68px;
  display: inline-flex;
  align-items: center;

  .subscription-coverage {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-left: 8px;
  }
`;

const StyledSliderInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;

  .cover-up-slider {
    margin-top: 25px;
  }
`;

const StyledCoverInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  .subscribe-months-fee {
    display: flex;
    align-items: center;
    margin-left: 55px;
    color: #c3c4c3;
  }

  .subscribe-months-input-wrapper {
    margin: 0 10px;
  }
`;

const StyledSummary = styled.div`
  margin-top: 47px;

  .total-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
  }

  hr {
    border: none;
    border-bottom: 1px solid #404141;
    width: calc(100% - 60px);
    transform: translateX(30px) translateY(-15px);
  }
`;

const StyledCost = styled.table`
  margin: 8px auto 0 auto;

  @media only screen and (max-height: 850px) {
    margin-top: 0;
  }

  tr {
    td:first-child {
      text-align: right;
    }

    td:nth-child(2) {
      text-align: left;
    }
  }

  .matic-cost {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: #878888;
  }

  .usd-cost {
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
  }

  .cost-currency {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
`;

const StyledOrderBtnWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 356px;
  margin: 24px auto 0 auto;

  .free-usage-label {
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #c3c4c3;
    margin-bottom: 5px;
  }

  .add-to-order-btn {
    width: 100%;
    display: block;
    margin-bottom: 21px;
  }

  .add-to-card-error {
    display: flex;

    .add-to-card-error-msg {
      color: #878888;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 100% !important;
  }
`;

const StyledSliderBtn = styled.button``;

interface IProps {
  pricingCoverage: Array<{
    subscriptionFee: number;
    coverage: number;
  }>;
  token: string;
  onAddToCart: (coverage: number, subscriptionFee: number, months: number) => void;
  maxSubscriptionPeriod: number;
  addToOrderDisabled: boolean;
  disabled: boolean;
  mobile: boolean;
  mobileVisible: boolean;
  onCloseMobileSubscribe: () => void;
  cartProviderName?: string;
}

export const Subscribe: FunctionComponent<IProps> = ({
  pricingCoverage = [],
  token = '',
  onAddToCart,
  maxSubscriptionPeriod = 3,
  addToOrderDisabled = false,
  disabled = true,
  mobile = false,
  mobileVisible = false,
  onCloseMobileSubscribe,
  cartProviderName = '',
}) => {
  const [months, setMonths] = useState(maxSubscriptionPeriod);
  const [activeCoverageIndex, setActiveCoverageIndex] = useState(0);
  const activeCoverageValue = pricingCoverage[activeCoverageIndex]?.coverage || 0;
  const activeSubscriptionFee = pricingCoverage[activeCoverageIndex]?.subscriptionFee || 0;

  const sliderMarks = pricingCoverage.reduce((acc: Record<string, any>, item) => {
    // eslint-disable-next-line functional/immutable-data
    acc[item.coverage] = item.coverage >= 1000 ? `$${item.coverage / 1000}k` : `$${item.coverage}`;
    return acc;
  }, {});

  const handleCoverageChange = (newVal: number) => {
    if (newVal > activeCoverageIndex && activeCoverageIndex < pricingCoverage.length - 1) {
      setActiveCoverageIndex(activeCoverageIndex + 1);
    } else if (newVal < activeCoverageIndex && activeCoverageIndex > 0) {
      setActiveCoverageIndex(activeCoverageIndex - 1);
    }
  };

  const handleMonthChange = (newValue: number) => {
    if (newValue >= 1 && newValue <= maxSubscriptionPeriod) {
      setMonths(newValue);
    }
  };

  const handleAddToCart = () => {
    if (onAddToCart && typeof onAddToCart === 'function') {
      onAddToCart(activeCoverageValue, activeSubscriptionFee, months);
    }
  };

  const handleSliderBtn = (action: 'increase' | 'decrease') => {
    if (action === 'increase') {
      handleCoverageChange(activeCoverageIndex + 1);
    } else {
      handleCoverageChange(activeCoverageIndex - 1);
    }
  };

  const handleCloseMobileSubscribe = () => {
    if (onCloseMobileSubscribe && typeof onCloseMobileSubscribe === 'function') {
      onCloseMobileSubscribe();
    }
  };

  return (
    <StyledWrapper className="subscribe-section" mobile={mobile} mobileVisible={mobileVisible}>
      <div className="subscribe-section-container">
        {mobile && mobileVisible && (
          <button type="button" onClick={handleCloseMobileSubscribe} className="subscribe-close-btn">
            <TimesIcon />
          </button>
        )}
        <StyledTitle className="subscribe-section-title">{SUBSCRIBE_HEADER}</StyledTitle>
        <StyledDescription>{SUBSCRIBE_DESCRIPTION}</StyledDescription>
        <StyledCoverage className="subscribe-coverage-label">
          {COVERED_UP_TO}:{' '}
          <span className="subscription-coverage">{!disabled ? `$${activeCoverageValue}` : NO_VALUE}</span>
        </StyledCoverage>
        <StyledSliderInputs>
          <StyledSliderBtn
            type="button"
            className="increase-decrease-btn"
            onClick={() => handleSliderBtn('decrease')}
            disabled={disabled}
          >
            -
          </StyledSliderBtn>
          <CustomSlider
            min={0}
            max={pricingCoverage.length - 1}
            marks={sliderMarks}
            value={activeCoverageIndex}
            step={null}
            wrapperClassName="cover-up-slider"
            onChange={(value) => {
              if (!Array.isArray(value)) {
                handleCoverageChange(value);
              }
            }}
            disabled={disabled}
          />
          <StyledSliderBtn
            type="button"
            className="increase-decrease-btn"
            onClick={() => handleSliderBtn('increase')}
            disabled={disabled}
          >
            +
          </StyledSliderBtn>
        </StyledSliderInputs>
        <StyledSectionName className="subscribe-monthly-fee-label">{MONTHLY_FEE}</StyledSectionName>
        <StyledCoverInputs>
          <div>${!disabled ? activeSubscriptionFee : NO_VALUE}/mo</div>
          <div className="subscribe-months-fee">
            for
            <NumericInput
              value={months}
              onChange={handleMonthChange}
              min={1}
              max={12}
              className="subscribe-months-input-wrapper"
              disabled={disabled}
            />
            Months
          </div>
        </StyledCoverInputs>
        <StyledSummary className="subscribe-summary">
          <span className="total-text">{TOTAL}</span>
          <hr />
        </StyledSummary>
        <StyledCost>
          <tr className="matic-cost">
            <td>{!disabled ? activeSubscriptionFee * months : NO_VALUE}</td> <td className="cost-currency">{USD}</td>
          </tr>
          <tr className="usd-cost">
            <td>{NO_VALUE}</td> <td className="cost-currency">{token.toUpperCase()}</td>
          </tr>
        </StyledCost>
        <StyledOrderBtnWrapper className="subscribe-order-container">
          <div className="free-usage-label">
            <b>Free</b> {TRIAL_UNTIL}
          </div>
          <Button
            variant={EButtonVariants.PRIMARY}
            label={ADD_TO_ORDER}
            onClick={handleAddToCart}
            disabled={addToOrderDisabled}
            className="add-to-order-btn"
          />
          {addToOrderDisabled && cartProviderName && (
            <div className="add-to-card-error">
              <ErrorIcon />
              <span className="add-to-card-error-msg">
                You already have a product on {cartProviderName} in your order. You can&#39;t add products from
                different networks onto the same order.
              </span>
            </div>
          )}
        </StyledOrderBtnWrapper>
      </div>
    </StyledWrapper>
  );
};

export default Subscribe;
