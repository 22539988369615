import React, { ChangeEvent, FunctionComponent, memo } from 'react';
import styled, { css } from 'styled-components';
import { CLEAR } from 'constants/messages';
import { CustomCheckbox, GenericFilter } from 'components/shared';
import { SearchIcon, FilterIcon, TimesIcon } from 'components/shared/icons';
import { IFilterObj, IFilterOptions } from 'entities/types/filter';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  @media only screen and (max-width: 1152px) {
    flex-direction: column;

    .filter-icon {
      margin-right: 5px !important;
      margin-left: 0;
    }

    .filter-container {
      margin-top: 35px;
      justify-content: right;
    }
  }

  @media only screen and (max-width: 680px) {
    .filter-container {
      display: none;
    }
  }
`;
const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledSearClearBtn = styled.button`
  color: rgba(135, 136, 136, 1);
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  right: 12px;
  background-color: transparent;
  border: none;
  outline: none;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledInput = styled.input`
  width: 380px;
  padding: 15px 18px 15px 58px;
  background-color: var(--color-black);
  border: 1px solid rgba(64, 65, 65, 1);
  color: var(--font-color-white);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  transition: 0.3s all;

  &:active,
  &:focus {
    border: 1px solid rgba(135, 136, 136, 1);
    box-shadow: none;
    outline: none;

    &::placeholder {
      color: rgba(243, 243, 243, 1);
    }
  }

  &::placeholder {
    color: #6c6d6d;
    transition: 0.3s all;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
`;

const StyledFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .filter-icon {
    margin-right: 15px;
  }
`;

const StyledClearBtn = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(124, 227, 203, 1);
  margin-left: 20px;

  &:disabled {
    color: rgba(50, 91, 81, 1);
    cursor: not-allowed;
  }
`;

const StyledFilterOption = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;

  input {
    margin-right: 9px;
    cursor: pointer;

    &:checked + img {
      opacity: 1;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(135, 136, 136, 1);
    text-transform: capitalize;
    cursor: pointer;

    ${({ checked }) =>
      checked &&
      css`
        color: rgba(195, 196, 195, 1);
      `}

    img {
      margin-right: 4px;
      cursor: pointer;
      opacity: 0.7;

      ${({ checked }) =>
        checked &&
        css`
          opacity: 1;
        `}
    }
  }
`;

interface IProps {
  searchValue: string;
  filterObj: IFilterObj;
  filterOptions: IFilterOptions;
  onFilterChange: (newFilter: IFilterObj) => void;
  onSearchChange: (newValue: string) => void;
  onClearFilters: () => void;
}

export const TableToolbox: FunctionComponent<IProps> = memo(
  ({ searchValue = '', filterObj, filterOptions, onFilterChange, onSearchChange, onClearFilters }) => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => onSearchChange(event.target.value);

    const handleClearFilters = () => {
      if (onClearFilters && typeof onClearFilters === 'function') {
        onClearFilters();
      }
    };

    const handleCategoryFilter = (category: string) => {
      if (!filterObj.categories.includes(category)) {
        onFilterChange({
          ...filterObj,
          categories: [...filterObj.categories, category],
        });
      } else {
        onFilterChange({
          ...filterObj,
          categories: filterObj.categories.filter((name) => name !== category),
        });
      }
    };

    const handleNetworkFilter = (chainId: string) => {
      if (!filterObj.chainIds.includes(chainId)) {
        onFilterChange({
          ...filterObj,
          chainIds: [...filterObj.chainIds, chainId],
        });
      } else {
        onFilterChange({
          ...filterObj,
          chainIds: filterObj.chainIds.filter((id) => id !== chainId),
        });
      }
    };

    const handleSourceFilter = (sourceName: string) => {
      if (!filterObj.sourceNames.includes(sourceName)) {
        onFilterChange({
          ...filterObj,
          sourceNames: [...filterObj.sourceNames, sourceName],
        });
      } else {
        onFilterChange({
          ...filterObj,
          sourceNames: filterObj.sourceNames.filter((name) => name !== sourceName),
        });
      }
    };

    const handleClearSearchInput = () => {
      onSearchChange('');
    };

    const getFilterBtnLabel = (filter: 'category' | 'source' | 'chain') => {
      switch (filter) {
        case 'category':
          if (filterObj.categories.length === 1) {
            // leave 2 spaces for text to not extend
            return 'Category  ';
          }
          if (filterObj.categories.length > 1) {
            return `Categories`;
          }
          return 'Category  ';

        case 'chain':
          if (filterObj.chainIds.length === 1) {
            return 'Network  ';
          }
          if (filterObj.chainIds.length > 1) {
            return `Networks`;
          }
          return 'Network  ';

        case 'source':
          if (filterObj.sourceNames.length === 1) {
            return 'Source  ';
          }
          if (filterObj.sourceNames.length > 1) {
            return `Sources`;
          }
          return 'Source  ';
        default:
          break;
      }
      return '';
    };

    const handleClearFilter = (filter: 'category' | 'source' | 'chain') => {
      switch (filter) {
        case 'category':
          onFilterChange({ ...filterObj, categories: [] });
          break;
        case 'chain':
          onFilterChange({ ...filterObj, chainIds: [] });
          break;
        case 'source':
          onFilterChange({ ...filterObj, sourceNames: [] });
          break;
        default:
          break;
      }
    };

    const clearAllActive =
      filterObj.categories.length > 0 || filterObj.chainIds.length > 0 || filterObj.sourceNames.length > 0;

    return (
      <StyledWrapper>
        <StyledInputWrapper>
          <StyledInput type="text" placeholder="Search" value={searchValue} onChange={handleInputChange} />
          <StyledSearchIcon />
          {searchValue && (
            <StyledSearClearBtn type="button" onClick={handleClearSearchInput}>
              <TimesIcon width={16} height={16} />
            </StyledSearClearBtn>
          )}
        </StyledInputWrapper>
        <StyledFilterWrapper className="filter-container">
          <FilterIcon className="filter-icon" />
          <GenericFilter
            active={filterObj.sourceNames.length > 0}
            popoverContent={filterOptions.sources.map((source) => (
              <StyledFilterOption key={source.name} checked={filterObj.sourceNames.includes(source.name)}>
                <CustomCheckbox
                  label={
                    <>
                      <img alt="source logo" src={source.logoPath} height={15} /> {source.name}
                    </>
                  }
                  id={`checkbox-for-source-${source.name}`}
                  checked={filterObj.sourceNames.includes(source.name)}
                  value={source.name}
                  onChange={() => handleSourceFilter(source.name)}
                />
              </StyledFilterOption>
            ))}
            btnLabel={getFilterBtnLabel('source')}
            onClear={() => handleClearFilter('source')}
            count={filterObj.sourceNames.length}
          />
          <GenericFilter
            active={filterObj.chainIds.length > 0}
            popoverContent={filterOptions.chains.map((chain) => (
              <StyledFilterOption key={chain.id} checked={filterObj.chainIds.includes(chain.id)}>
                <CustomCheckbox
                  label={
                    <>
                      <img alt="chain logo" src={chain.logoPath} height={15} />
                      {chain.fullName}
                    </>
                  }
                  id={`checkbox-for-chain-${chain.id}`}
                  checked={filterObj.chainIds.includes(chain.id)}
                  value={chain.id}
                  onChange={() => handleNetworkFilter(chain.id)}
                />
              </StyledFilterOption>
            ))}
            btnLabel={getFilterBtnLabel('chain')}
            onClear={() => handleClearFilter('chain')}
            count={filterObj.chainIds.length}
          />
          <GenericFilter
            active={filterObj.categories.length > 0}
            popoverContent={filterOptions.categories.map((category) => (
              <StyledFilterOption key={category} checked={filterObj.categories.includes(category)}>
                <CustomCheckbox
                  label={category}
                  id={`checkbox-for-${category}`}
                  checked={filterObj.categories.includes(category)}
                  value={category}
                  onChange={() => handleCategoryFilter(category)}
                />
              </StyledFilterOption>
            ))}
            btnLabel={getFilterBtnLabel('category')}
            onClear={() => handleClearFilter('category')}
            count={filterObj.categories.length}
          />

          <StyledClearBtn type="button" onClick={handleClearFilters} disabled={!clearAllActive}>
            {CLEAR}
          </StyledClearBtn>
        </StyledFilterWrapper>
      </StyledWrapper>
    );
  }
);
