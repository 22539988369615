import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import useTablesStore from 'stores/tables/tables.store';
import { BEACONS_HELMET_TITLE, BEACONS_PAGE_DESCRIPTION, BEACONS_PAGE_TITLE } from 'constants/messages';
import DatafeedLogos from 'components/shared/datafeed/datafeed-logos';
import useWindowDimensions from 'hooks/window-dimensions';
import Footer from 'components/layout/footer/footer';
import { Table } from 'components/shared';
import { ISortingObj } from 'entities/types/sorting';
import { IFilterObj } from 'entities/types/filter';
import { TableToolbox } from 'components/shared/table/table-toolbox/table-toolbox';
import { IBeacon, IBeaconChain, IBeaconSource } from 'entities/types/beacon';
import { commonResponsiveTableStyles } from 'constants/styles';
import { capitalize } from 'utils/utils';
import { EEntitiesTableColumnClassNames } from 'entities/enums/column-classNames';
import PageHeader from '../../components/layout/page-header/page-header';

const StyledWrapper = styled.div`
  ${commonResponsiveTableStyles};
`;

const BeaconsPage = () => {
  const {
    beacons,
    totalBeacons,
    getBeacons,
    onChainValues,
    beaconFilterOptions,
    beaconFilterObj,
    onBeaconFilterChange,
    onClearBeaconFilter,
    logoPaths,
  } = useTablesStore((store) => ({
    beacons: store.beacons,
    totalBeacons: store.totalBeacons,
    getBeacons: store.getBeacons,
    onChainValues: store.onChainValues,
    beaconFilterOptions: store.beaconFilterOptions,
    beaconFilterObj: store.beaconFilterObj,
    onBeaconFilterChange: store.onBeaconFilterChange,
    onClearBeaconFilter: store.onClearBeaconFilter,
    logoPaths: store.logoPaths,
  }));
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortObj, setSortObj] = useState<ISortingObj>({ sortKey: '', sortType: '' });
  const { width } = useWindowDimensions();

  const handlePageChange = (newPage: number) => setCurrentPage(newPage);
  const handleSortingChange = (newObj: ISortingObj) => setSortObj(newObj);

  const updateBeaconsList = (sortObj: ISortingObj, filterObj: IFilterObj, page: number, searchValue: string) => {
    getBeacons(sortObj, filterObj, page, searchValue);
  };

  useEffect(() => {
    updateBeaconsList(sortObj, beaconFilterObj, currentPage, searchValue);
  }, [currentPage, sortObj, beaconFilterObj, searchValue]);

  const handleSearchValues = (newValue: string) => {
    setSearchValue(newValue);
  };

  const handleFilterValues = (newFilter: IFilterObj) => {
    onBeaconFilterChange(newFilter);
    // reset page
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    onClearBeaconFilter();
    // reset page
    setCurrentPage(1);
  };

  const handleRowClick = (row: any) => {
    navigate(`/beacons/${row.chain.name}/${row.beaconId}`);
  };

  const getHiddenColumns = useCallback(() => {
    switch (true) {
      case width <= 555:
        return ['category', 'pricingCoverage', 'onChainValue'];
      case width <= 875:
        return ['category', 'pricingCoverage'];
      case width <= 1020:
        return ['category'];
      default:
        return [];
    }
  }, [width]);

  const beaconColumns = [
    {
      label: 'Data Feed',
      sortable: true,
      dataKey: 'name',
      columnClassName: EEntitiesTableColumnClassNames.DATAFEED,
      render: (value: string, row: IBeacon) => (
        <span className="vertical-center">
          <DatafeedLogos logoNames={row.logoNames} logoPaths={logoPaths} /> <strong>{value || ''}</strong>
        </span>
      ),
    },
    {
      label: 'Source',
      sortable: true,
      dataKey: 'source',
      columnClassName: EEntitiesTableColumnClassNames.SOURCE,
      render: (sourceObj: IBeaconSource) => (
        <span className="vertical-center" title={sourceObj.name}>
          <img alt={`logo for ${sourceObj.name}`} src={sourceObj.logoPath || ''} className="table-logo" />
          {capitalize(sourceObj.name)}
        </span>
      ),
    },
    {
      label: 'Network',
      sortable: true,
      dataKey: 'chain',
      columnClassName: EEntitiesTableColumnClassNames.NETWORK,
      render: (chainObj: IBeaconChain) => (
        <span className="vertical-center">
          <img alt={`logo for ${chainObj?.fullName}`} src={chainObj?.logoPath || ''} className="table-logo" />{' '}
          {chainObj?.fullName}
        </span>
      ),
    },
    {
      label: 'On-chain Value',
      dataKey: 'onChainValue',
      columnClassName: EEntitiesTableColumnClassNames.ON_CHAIN_VALUE,
      render: (value: any, beacon: IBeacon) => (
        <span className="vertical-center">
          {!!onChainValues[`${beacon.beaconId}-${beacon.chain.id}`]?.value &&
          !onChainValues[`${beacon.beaconId}-${beacon.chain.id}`]?.loading ? (
            onChainValues[`${beacon.beaconId}-${beacon.chain.id}`]?.value?.toFixed(beacon.chainValueDecimals)
          ) : (
            <Skeleton width={60} />
          )}
        </span>
      ),
    },
    {
      label: 'Category',
      sortable: true,
      dataKey: 'category',
      columnClassName: EEntitiesTableColumnClassNames.CATEGORY,
    },
  ];

  return (
    <div>
      <StyledWrapper>
        <Helmet>
          <title>{BEACONS_HELMET_TITLE}</title>
        </Helmet>
        <PageHeader title={BEACONS_PAGE_TITLE} description={BEACONS_PAGE_DESCRIPTION} />
        <TableToolbox
          searchValue={searchValue}
          filterObj={beaconFilterObj}
          filterOptions={beaconFilterOptions}
          onSearchChange={handleSearchValues}
          onFilterChange={handleFilterValues}
          onClearFilters={handleClearFilters}
        />
        <Table
          className="beacons-table"
          columns={beaconColumns}
          rows={beacons}
          onRowClick={handleRowClick}
          totalItems={totalBeacons}
          currentPage={currentPage}
          sortObj={sortObj}
          onPageChange={handlePageChange}
          onSortChange={handleSortingChange}
          hiddenColumnsKeys={getHiddenColumns()}
          searchText={searchValue}
        />
      </StyledWrapper>
      <Footer />
    </div>
  );
};

export default BeaconsPage;
