import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SOMETHING_WENT_WRONG, TRY_RELOAD_THE_PAGE } from 'constants/messages';
import { RoundErrorIcon } from 'components/shared/icons';

const StyledWrapper = styled.div`
  position: fixed;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  padding: 9px 0;
  width: 504px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #030303;
  background: #f9e2fd;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.9);
  justify-content: center;
  z-index: 100;

  & svg {
    margin-right: 8px;
  }

  .error-link-btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    font-weight: 400;
    margin-right: 10px;
  }
`;

interface IProps {
  show: boolean;
}

export const ErrorBanner: FunctionComponent<IProps> = ({ show = true }) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <StyledWrapper style={{ display: show ? 'block' : 'none' }}>
      <RoundErrorIcon />
      {SOMETHING_WENT_WRONG}
      <button type="button" className="error-link-btn" onClick={handleReload}>
        {TRY_RELOAD_THE_PAGE}
      </button>
    </StyledWrapper>
  );
};
