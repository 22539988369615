import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { GreenDotIcon } from 'components/shared/icons/green-dot';

interface IProps {
  active: boolean;
  className?: string;
}

const StyledIcon = styled.svg<{ active: boolean }>`
  background-color: gray;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-left: 5px;
  border: 2px solid var(--color-black);
`;

export const StatusIcon: FunctionComponent<IProps> = ({ active, className = '' }) => {
  return active ? <GreenDotIcon className={className} /> : <StyledIcon className={className} active={active} />;
};
