import { css } from 'styled-components';
import { EEntitiesTableColumnClassNames } from 'entities/enums/column-classNames';

export const HEADER_HEIGHT_BIG = 100;
export const FOOTER_HEIGHT_BIG = 110;
export const MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT = 1140;
export const MEDIUM_WIDTH_ENTITY_BREAKPOINT = 1348;

export const containerStyles = css`
  width: 100%;
  padding: 0 134px;

  @media only screen and (max-width: ${MEDIUM_WIDTH_ENTITY_BREAKPOINT}px) {
    padding: 0 60px;
  }

  @media only screen and (max-width: 775px) {
    padding: 0 25px;
  }
`;

export const commonResponsiveTableStyles = css`
  min-height: calc(100vh - ${HEADER_HEIGHT_BIG}px - ${FOOTER_HEIGHT_BIG}px);

  .${EEntitiesTableColumnClassNames.DATAFEED},
    .${EEntitiesTableColumnClassNames.SOURCE},
    .${EEntitiesTableColumnClassNames.NETWORK},
    .${EEntitiesTableColumnClassNames.ON_CHAIN_VALUE},
    .${EEntitiesTableColumnClassNames.CATEGORY} {
    max-width: 20%;
    width: 20%;
  }

  .table {
    tbody tr {
      background-color: #030303;
      transition: 200ms ease-in border;

      &:nth-child(2n) {
        background-color: #121313 !important;
      }

      &:not(.table-empty-row):last-child:hover {
        border-bottom: 1px solid var(--color-emerald) !important;
      }

      &:not(.table-empty-row):hover {
        border-top: 1px solid var(--color-emerald) !important;
      }

      &:not(.table-empty-row):hover + tr {
        border-top: 1px solid var(--color-emerald) !important;
      }
    }
  }

  @media only screen and (max-width: 775px) {
    & .pagination-container ul {
      padding-left: 0 !important;
    }

    .table-container {
      padding-top: 30px;
    }

    .table-borders {
      border: none;
      padding: 0;
    }

    .table {
      th,
      td {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }

  @media only screen and (max-width: 475px) {
    .table-container {
      padding-top: 5px;

      .table {
        margin-top: 0;
      }
    }

    .table,
    .column-label {
      font-size: 12px;
      line-height: 150%;
    }

    .table {
      td,
      th {
        padding-left: 0;
      }

      tr {
        height: 56px;
      }
    }

    .feed-container {
      margin-right: 0;
      margin-left: 6px;
      .feed {
        width: 21px;
        height: 21px;
        font-size: 14px;
      }
    }
  }

  .table-logo {
    height: 22px;
    margin-right: 8px;
  }
`;
