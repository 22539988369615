import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  position: relative;
  text-decoration: none;
  color: var(--font-color-white);
  font-size: 14px;
  line-height: 24px;
  margin-left: 32px;
  margin-top: 4px;

  &:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0;
    background: linear-gradient(90deg, #7ce3cb 0%, #777ca1 100%);
    bottom: -5px;
    left: 0;
    transition: width 0.3s;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &:after {
        width: 100%;
      }
    `}
`;

interface IProps {
  to: string;
  label: string;
}

const HeaderLink: FunctionComponent<IProps> = ({ to, label }) => {
  const location = useLocation();

  return (
    <StyledLink to={to} $isActive={location.pathname === to || (to !== '/' && location.pathname.startsWith(to))}>
      {label}
    </StyledLink>
  );
};

export default HeaderLink;
