import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div``;

const StyledTitle = styled.h1`
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
  margin-top: 20px;
  margin-bottom: 8px;
`;

const StyledDescription = styled.h2`
  margin: 0;
  margin-bottom: 44px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  color: #c3c4c3;

  @media only screen and (max-width: 775px) {
    margin-bottom: 5px;
  }
`;

interface IProps {
  title: string;
  description: string;
}

const PageHeader: FunctionComponent<IProps> = ({ title = '', description = '' }) => {
  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  );
};

export default PageHeader;
