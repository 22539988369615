import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.span`
  display: inline-flex;
  margin-right: 8px;

  .feed {
    width: 36px;
    height: 36px;

    &:nth-child(1) {
      z-index: 3;
    }

    &:nth-child(2) {
      position: relative;
      left: -6px;
      z-index: 2;
    }
  }
`;

interface IProps {
  logoNames: Array<string>;
  logoPaths: Record<string, string>;
}

const DatafeedLogos: FunctionComponent<IProps> = ({ logoNames = [], logoPaths = {} }) => {
  return (
    <StyledWrapper className="feed-container">
      {logoNames.map((name) => (
        <img className="feed" title={name} key={name} src={logoPaths[name]} alt="logo" aria-hidden="true" />
      ))}
    </StyledWrapper>
  );
};

export default DatafeedLogos;
