import React, { FunctionComponent } from 'react';

export const FilterIcon: FunctionComponent<{ className?: string }> = ({ className = '' }) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.45638 15.2859C8.45638 15.6814 8.77589 16.001 9.17191 16.001H9.82613C10.2221 16.001 10.5417 15.6814 10.5417 15.2859L10.8125 10.906H8.18747L8.45638 15.2859ZM17.7816 0.000976562H1.21646C0.665189 0.000976562 0.320927 0.597625 0.597687 1.0736L7.93321 9.47584H11.0712L18.4048 1.0736C18.6771 0.597625 18.3328 0.000976562 17.7816 0.000976562Z"
        fill="#F3F3F3"
      />
    </svg>
  );
};
