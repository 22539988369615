import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ON_CHAIN_VALUE, VALUE_COULD_NOT_BE_RETRIEVED, VIEW_MORE_PARAMETERS } from 'constants/messages';
import { GithubLogoIcon } from 'components/shared/icons';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { format, intervalToDuration } from 'date-fns';

const StyledOnChainValueWrapper = styled.div`
  width: 50%;
  border: 1px solid #878888;

  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  .on-chain-content {
    background-color: var(--color-black);
    margin: -32px 32px;
    padding: 20px 0;
  }

  .on-chain-title {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    padding: 0 8px;
  }

  .on-chain-value {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 52px;
    margin-top: 12px;
  }

  .on-chain-additional {
    display: flex;
    justify-content: space-between;
    padding: 32px 0 24px 0;
    color: #c3c4c3;
  }

  .on-chain-github {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .github-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #c3c4c3;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;

    svg {
      margin-right: 8px;
    }
  }
`;

interface IProps {
  onChainValue: string | undefined;
  lastTransactionTimestamp: number | undefined;
  loading: boolean;
  deviationThreshold: number | undefined;
  getGithubLink: () => string;
}

const OnChainValue: FunctionComponent<IProps> = ({
  onChainValue,
  lastTransactionTimestamp = 0,
  loading,
  deviationThreshold,
  getGithubLink,
}) => {
  const intervalRef = useRef<any>(null);
  const [currentTimestamp, setCurrentTimestamp] = useState(new Date().getTime());
  const lastUpdatedObj = intervalToDuration({
    start: new Date(lastTransactionTimestamp as number),
    end: new Date(currentTimestamp),
  });

  // Update timestamp every second
  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    intervalRef.current = setInterval(() => setCurrentTimestamp(new Date().getTime()), 1000 * 60);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const renderOnChainValue = () => {
    const valuePresent = onChainValue !== null && onChainValue !== undefined && !Number.isNaN(onChainValue);

    return !loading ? valuePresent ? `${onChainValue}` : VALUE_COULD_NOT_BE_RETRIEVED : <Skeleton />;
  };

  const minuteDiff =
    !loading && lastTransactionTimestamp ? (
      `${lastUpdatedObj.years ? `${lastUpdatedObj.years}Y` : ''} ${
        lastUpdatedObj.months ? `${lastUpdatedObj.months}M` : ''
      } ${lastUpdatedObj.weeks ? `${lastUpdatedObj.weeks}W` : ''} ${
        lastUpdatedObj.days ? `${lastUpdatedObj.days}D` : ''
      } ${lastUpdatedObj.hours ? `${lastUpdatedObj.hours}H` : 'OH'} ${
        lastUpdatedObj.minutes ? `${lastUpdatedObj.minutes}M` : ''
      } ago`
    ) : (
      <Skeleton />
    );

  const lastTransactionDate =
    !loading && lastTransactionTimestamp ? format(new Date(lastTransactionTimestamp), 'HH:mm | dd MMMM') : <Skeleton />;

  return (
    <StyledOnChainValueWrapper className="on-chain-container">
      <div className="on-chain-content">
        <div className="on-chain-title">{ON_CHAIN_VALUE}</div>
        <div className="on-chain-value">{renderOnChainValue()}</div>
        <div className="on-chain-additional">
          <div className="on-chain-deviation">
            Deviation Threshold:
            <br />
            <strong>{deviationThreshold ? `${deviationThreshold}%` : <Skeleton />}</strong>
          </div>
          <div className="on-chain-last-updated">
            Last Updated:
            <br /> <strong>{minuteDiff}</strong>
            <br />
            <small>{lastTransactionDate}</small>
          </div>
        </div>
        <div className="on-chain-github">
          <a
            className="github-link"
            href={getGithubLink()}
            title="link to github source"
            target="_blank"
            rel="noreferrer"
          >
            <GithubLogoIcon />
            {VIEW_MORE_PARAMETERS}
          </a>
        </div>
      </div>
    </StyledOnChainValueWrapper>
  );
};

export default OnChainValue;
