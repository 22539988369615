import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.svg`
  margin-right: 57px;
`;

const Logo = () => {
  return (
    <StyledLogo width="117" height="37" viewBox="0 0 117 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8734 0.204036C20.7174 -0.065194 20.3268 -0.063844 20.1726 0.206536L0.0533495 35.4614C-0.0996705 35.7296 0.0947295 36.0626 0.40442 36.0626H40.9603C41.2712 36.0626 41.4655 35.7273 41.3101 35.4591L20.8734 0.204036ZM16.4923 23.0668L16.4912 23.0652L16.4924 23.0668L23.7032 21.1025L21.7541 28.2355L21.7535 28.2365L16.4924 23.0668"
        fill="#F3F3F3"
      />
      <path
        d="M0.40442 36.0623H8.5539L16.0485 7.43262L0.0533495 35.4611C-0.0996705 35.7293 0.0947295 36.0623 0.40442 36.0623Z"
        fill="#F3F3F3"
      />
      <path
        d="M37.3089 28.5576L8.55371 36.0634H40.9601C41.2711 36.0634 41.4654 35.7279 41.3099 35.4598L37.3089 28.5576Z"
        fill="#F3F3F3"
      />
      <path
        d="M32.897 24.1728L37.3092 28.5569L20.8737 0.204133C20.7177 -0.065097 20.3272 -0.063937 20.173 0.206423L16.0488 7.43304L32.879 24.1549L32.897 24.1728Z"
        fill="#F3F3F3"
      />
      <path d="M8.55371 36.0608L14.4789 34.5143L14.4456 13.5537L8.55371 36.0608Z" fill="#F3F3F3" />
      <path d="M14.4453 13.5551L32.8961 24.1724L16.0481 7.43262L14.4453 13.5551Z" fill="#F3F3F3" />
      <path d="M14.4785 34.5162L37.3086 28.557H37.3085L32.8961 24.1729L14.4785 34.5162Z" fill="#F3F3F3" />
      <path d="M14.4793 34.5169L18.4312 32.2977L14.4531 18.0029L14.4793 34.5169Z" fill="#F3F3F3" />
      <path d="M32.8973 24.1742L29.0121 21.9385L18.4316 32.2983L32.8973 24.1742Z" fill="#F3F3F3" />
      <path d="M14.4534 18.0023L29.0118 21.9373L14.4463 13.5557L14.4534 18.0023Z" fill="#F3F3F3" />
      <path
        d="M23.7037 21.1017L25.9391 21.1055L14.4531 18.001L15.3109 21.0829L23.6834 21.1017H23.7037Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.4705 23.0269L15.3115 21.083L18.4319 32.2957L20.6421 30.1317L16.4935 23.066L16.4705 23.0269Z"
        fill="#F3F3F3"
      />
      <path d="M25.9392 21.1064L20.6416 30.1328L29.0117 21.9369L25.9392 21.1064Z" fill="#F3F3F3" />
      <path d="M23.7025 21.1018L15.3096 21.083L16.4916 23.0662L23.7025 21.1018Z" fill="#F3F3F3" />
      <path d="M21.7525 28.2351L16.4912 23.0654L20.6398 30.1309L21.7525 28.2351Z" fill="#F3F3F3" />
      <path d="M25.9379 21.1063L23.7025 21.1025L21.7529 28.2377L25.9379 21.1063Z" fill="#F3F3F3" />
      <path
        d="M0.40442 36.0623H8.5539L16.0485 7.43262L0.0533495 35.4611C-0.0996705 35.7293 0.0947295 36.0623 0.40442 36.0623Z"
        fill="url(#paint0_linear_358_12359)"
      />
      <path
        d="M37.3089 28.5576L8.55371 36.0634H40.9601C41.2711 36.0634 41.4654 35.728 41.3099 35.4598L37.3089 28.5576Z"
        fill="url(#paint1_linear_358_12359)"
      />
      <path
        d="M32.897 24.1728L37.3092 28.5569L20.8737 0.20401C20.7177 -0.0651604 20.3272 -0.0638104 20.173 0.20636L16.0488 7.43298L32.879 24.1549L32.897 24.1728Z"
        fill="url(#paint2_linear_358_12359)"
      />
      <path
        d="M8.55371 36.0608L14.4789 34.5143L14.4456 13.5537L8.55371 36.0608Z"
        fill="url(#paint3_linear_358_12359)"
      />
      <path
        d="M14.4453 13.5551L32.8961 24.1724L16.0481 7.43262L14.4453 13.5551Z"
        fill="url(#paint4_linear_358_12359)"
      />
      <path
        d="M14.4785 34.5161L37.3086 28.557H37.3085L32.8961 24.1729L14.4785 34.5161Z"
        fill="url(#paint5_linear_358_12359)"
      />
      <path
        d="M14.4793 34.5169L18.4312 32.2977L14.4531 18.0029L14.4793 34.5169Z"
        fill="url(#paint6_linear_358_12359)"
      />
      <path
        d="M32.8973 24.1742L29.0121 21.9385L18.4316 32.2983L32.8973 24.1742Z"
        fill="url(#paint7_linear_358_12359)"
      />
      <path
        d="M14.4534 18.0023L29.0118 21.9373L14.4463 13.5557L14.4534 18.0023Z"
        fill="url(#paint8_linear_358_12359)"
      />
      <path
        d="M23.7037 21.1018L25.9391 21.1055L14.4531 18.001L15.3109 21.0831L23.6834 21.1018H23.7037Z"
        fill="url(#paint9_linear_358_12359)"
      />
      <path
        d="M16.4705 23.0269L15.3115 21.083L18.4319 32.2957L20.6421 30.1317L16.4935 23.0661L16.4705 23.0269Z"
        fill="url(#paint10_linear_358_12359)"
      />
      <path
        d="M25.9392 21.1064L20.6416 30.1326L29.0117 21.9368L25.9392 21.1064Z"
        fill="url(#paint11_linear_358_12359)"
      />
      <path
        d="M23.7025 21.1017L15.3096 21.083L16.4916 23.0662L23.7025 21.1017Z"
        fill="url(#paint12_linear_358_12359)"
      />
      <path d="M21.7525 28.235L16.4912 23.0654L20.6398 30.1309L21.7525 28.235Z" fill="url(#paint13_linear_358_12359)" />
      <path
        d="M25.9379 21.1063L23.7025 21.1025L21.7529 28.2377L25.9379 21.1063Z"
        fill="url(#paint14_linear_358_12359)"
      />
      <path
        d="M64.7999 21.3125L59.8002 10.0687L54.8315 21.3125L53.8882 23.4423L51.7941 28.1337C51.4867 28.822 50.8014 29.2655 50.0449 29.2655H48.6592L58.3851 7.71985C58.5109 7.42774 58.6996 7.20831 58.9509 7.06201C59.2027 6.91614 59.4749 6.84277 59.7686 6.84277C60.0831 6.84277 60.3663 6.92102 60.6177 7.07776C60.8693 7.23432 61.0579 7.44873 61.1837 7.71985L70.9133 29.2678L69.5528 29.2667C68.7928 29.2661 68.1052 28.8179 67.8007 28.1245L65.7434 23.4423L64.7999 21.3125Z"
        fill="#F3F3F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.2656 29.2651V8.28301C74.2656 7.90722 74.386 7.60479 74.6273 7.37481C74.8682 7.14526 75.1879 7.03027 75.5864 7.03027H83.0391C85.3661 7.03027 87.1744 7.59411 88.4635 8.72155C89.7528 9.84917 90.3975 11.4463 90.3975 13.5137C90.3975 15.5807 89.7476 17.1888 88.4479 18.3368C87.1477 19.4855 85.345 20.0593 83.0391 20.0593H76.8443V27.3586C76.8443 28.4115 75.9872 29.2651 74.9298 29.2651L74.2656 29.2651ZM82.787 17.9286C86.1622 17.9286 87.8499 16.4567 87.8499 13.5128C87.8499 10.6105 86.1622 9.15918 82.787 9.15918H76.8438V17.9286H82.787Z"
        fill="#F3F3F3"
      />
      <path
        d="M94.8623 27.3586V7.03027H95.5267C96.5839 7.03027 97.4408 7.88385 97.4408 8.93695V29.2651H96.7766C95.7194 29.2651 94.8623 28.4115 94.8623 27.3587L94.8623 27.3586Z"
        fill="#F3F3F3"
      />
      <path
        d="M103.887 25.0376C104.851 25.8102 105.794 26.374 106.717 26.7288C107.639 27.0841 108.635 27.2614 109.704 27.2614C111.256 27.2614 112.435 26.9065 113.242 26.1963C114.049 25.4868 114.453 24.4635 114.453 23.127C114.453 20.4337 112.755 19.0868 109.359 19.0868H107.692C107.356 19.0868 107.089 18.977 106.89 18.7579C106.691 18.5387 106.591 18.2833 106.591 17.9906C106.591 17.6985 106.691 17.4478 106.89 17.239C107.089 17.0304 107.356 16.9257 107.692 16.9257H108.855C110.491 16.9257 111.748 16.5654 112.629 15.845C113.509 15.1248 113.95 14.0865 113.95 12.7288C113.95 11.5387 113.593 10.6255 112.881 9.98828C112.168 9.35162 111.161 9.03308 109.862 9.03308C108.876 9.03308 107.922 9.21606 107 9.58118C106.077 9.94672 105.155 10.5051 104.233 11.2567L104.031 10.9575C103.414 10.0442 103.692 8.78974 104.654 8.24713C105.114 7.98767 105.608 7.75879 106.135 7.56091C107.362 7.10199 108.625 6.87207 109.925 6.87207C111.937 6.87207 113.536 7.3786 114.72 8.39105C115.904 9.40405 116.497 10.7665 116.497 12.4783C116.497 13.752 116.177 14.8639 115.538 15.8138C114.898 16.7641 114.013 17.4478 112.881 17.8653C114.222 18.2411 115.244 18.8887 115.947 19.8071C116.649 20.7262 117 21.8639 117 23.2211C117 25.1423 116.35 26.6558 115.05 27.7624C113.75 28.8692 111.979 29.4224 109.736 29.4224C108.373 29.4224 107.068 29.1929 105.821 28.7333C105.298 28.5411 104.81 28.3194 104.356 28.0685C103.371 27.5249 103.06 26.2627 103.688 25.3324L103.887 25.0376Z"
        fill="#F3F3F3"
      />
      <rect x="53.2803" y="21.3799" width="13.013" height="2.12765" fill="#F3F3F3" />
      <defs>
        <linearGradient
          id="paint0_linear_358_12359"
          x1="17.2631"
          y1="33.3662"
          x2="8.65679"
          y2="7.73969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3C4C3" />
          <stop offset="0.0948988" stopColor="#D7D8D7" />
          <stop offset="0.212581" stopColor="#E9E9E9" />
          <stop offset="0.355404" stopColor="#F5F5F5" />
          <stop offset="0.547581" stopColor="#FCFCFC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_358_12359"
          x1="1.14331"
          y1="24.9941"
          x2="-0.139548"
          y2="42.7337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3C4C3" />
          <stop offset="0.0948988" stopColor="#D7D8D7" />
          <stop offset="0.212581" stopColor="#E9E9E9" />
          <stop offset="0.355404" stopColor="#F5F5F5" />
          <stop offset="0.547581" stopColor="#FCFCFC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_358_12359"
          x1="11.4002"
          y1="25.5762"
          x2="38.1981"
          y2="32.1641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3C4C3" />
          <stop offset="0.0948988" stopColor="#D7D8D7" />
          <stop offset="0.212581" stopColor="#E9E9E9" />
          <stop offset="0.355404" stopColor="#F5F5F5" />
          <stop offset="0.547581" stopColor="#FCFCFC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_358_12359"
          x1="15.4919"
          y1="31.2934"
          x2="3.96124"
          y2="23.7206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7B8B8" />
          <stop offset="0.114546" stopColor="#C9CACA" />
          <stop offset="0.303782" stopColor="#E0E1E1" />
          <stop offset="0.50531" stopColor="#F1F1F1" />
          <stop offset="0.725645" stopColor="#FBFBFB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_358_12359"
          x1="9.35675"
          y1="20.5117"
          x2="28.8548"
          y2="31.0158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7B8B8" />
          <stop offset="0.114546" stopColor="#C9CACA" />
          <stop offset="0.303782" stopColor="#E0E1E1" />
          <stop offset="0.50531" stopColor="#F1F1F1" />
          <stop offset="0.725645" stopColor="#FBFBFB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_358_12359"
          x1="20.5821"
          y1="21.0714"
          x2="15.8783"
          y2="37.6542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7B8B8" />
          <stop offset="0.114546" stopColor="#C9CACA" />
          <stop offset="0.303782" stopColor="#E0E1E1" />
          <stop offset="0.50531" stopColor="#F1F1F1" />
          <stop offset="0.725645" stopColor="#FBFBFB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_358_12359"
          x1="18.5026"
          y1="24.7703"
          x2="11.3221"
          y2="23.323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7B8B8" />
          <stop offset="0.211828" stopColor="#CDCECE" />
          <stop offset="0.518815" stopColor="#E8E9E9" />
          <stop offset="0.791553" stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_358_12359"
          x1="23.2314"
          y1="18.5756"
          x2="16.4929"
          y2="32.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7B8B8" />
          <stop offset="0.211828" stopColor="#CDCECE" />
          <stop offset="0.518815" stopColor="#E8E9E9" />
          <stop offset="0.791553" stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_358_12359"
          x1="10.4997"
          y1="20.3124"
          x2="22.4109"
          y2="29.2451"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7B8B8" />
          <stop offset="0.211828" stopColor="#CDCECE" />
          <stop offset="0.518815" stopColor="#E8E9E9" />
          <stop offset="0.791553" stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_358_12359"
          x1="12.6441"
          y1="21.0748"
          x2="18.5198"
          y2="27.379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABACAC" />
          <stop offset="0.187095" stopColor="#BCBDBD" />
          <stop offset="0.729612" stopColor="#ECECEC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_358_12359"
          x1="22.1968"
          y1="26.3583"
          x2="14.1334"
          y2="22.6368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABACAC" />
          <stop offset="0.187095" stopColor="#BCBDBD" />
          <stop offset="0.729612" stopColor="#ECECEC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_358_12359"
          x1="23.3589"
          y1="17.6295"
          x2="16.2801"
          y2="26.6179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABACAC" />
          <stop offset="0.187095" stopColor="#BCBDBD" />
          <stop offset="0.729612" stopColor="#ECECEC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_358_12359"
          x1="21.6246"
          y1="23.3315"
          x2="22.1882"
          y2="19.5731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9FA0A0" />
          <stop offset="0.174311" stopColor="#A4A5A5" />
          <stop offset="0.402447" stopColor="#B5B5B5" />
          <stop offset="0.660365" stopColor="#D0D0D0" />
          <stop offset="0.938425" stopColor="#F5F5F5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_358_12359"
          x1="23.3737"
          y1="25.0949"
          x2="16.6042"
          y2="21.9579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9FA0A0" />
          <stop offset="0.174311" stopColor="#A4A5A5" />
          <stop offset="0.402447" stopColor="#B5B5B5" />
          <stop offset="0.660365" stopColor="#D0D0D0" />
          <stop offset="0.938425" stopColor="#F5F5F5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_358_12359"
          x1="22.2834"
          y1="19.706"
          x2="18.8964"
          y2="26.2873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9FA0A0" />
          <stop offset="0.174311" stopColor="#A4A5A5" />
          <stop offset="0.402447" stopColor="#B5B5B5" />
          <stop offset="0.660365" stopColor="#D0D0D0" />
          <stop offset="0.938425" stopColor="#F5F5F5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </StyledLogo>
  );
};

export default Logo;
