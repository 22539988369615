import React, { FunctionComponent, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { Popover } from '@idui/react-popover';
import { COPIED } from 'constants/messages';

const StyledWrapper = styled.span`
  text-transform: none;
  & > * {
    display: inline-grid !important;
  }
`;

const CustomPopover = styled(Popover).attrs({
  arrowColor: 'black',
})`
  background-color: black;
  border-radius: 0;
  border: 2px solid black;
  box-shadow: none;
  padding: 10px;

  .idui-popover__arrow {
    box-shadow: none;
  }
`;

const HiddenTextSelection = styled.textarea`
  position: absolute;
  top: -9999px;
  right: 9999px;
  color: transparent;
`;

const StyledContainer = styled.span``;

interface IProps {
  copyString: string;
  children: ReactNode;
  className?: string;
}

export const CopyContainer: FunctionComponent<IProps> = ({ children, className = '', copyString = '' }) => {
  const [popover, setPopover] = useState({ open: false, content: '' });
  const textRef = useRef<HTMLTextAreaElement>(null);

  const handleCopy = async (e: any) => {
    if (navigator.clipboard) {
      try {
        await navigator?.clipboard?.writeText(copyString);
        setPopover({ open: true, content: COPIED });
      } catch (e: any) {
        setPopover({ open: true, content: e.message });
      } finally {
        setTimeout(() => {
          setPopover({ open: false, content: '' });
        }, 3000);
      }
    } else if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      setPopover({ open: true, content: COPIED });
      setTimeout(() => {
        setPopover({ open: false, content: '' });
      }, 3000);
    }
  };
  return (
    <StyledWrapper>
      <CustomPopover content={popover.content} isOpen={popover.open}>
        <StyledContainer className={className} onClick={handleCopy}>
          {children}
        </StyledContainer>
      </CustomPopover>
      <HiddenTextSelection ref={textRef} value={copyString} readOnly />
    </StyledWrapper>
  );
};
