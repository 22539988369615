import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import './index.css';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElem = document.getElementById('root');
const root = createRoot(rootElem as Element);

const ThisRouter = process.env.REACT_APP_PREVIEW_BUILD === 'TRUE' ? MemoryRouter : BrowserRouter;

root.render(
  <StrictMode>
    <ThisRouter>
      <SkeletonTheme baseColor="#141514" highlightColor="#3d3d3b">
        <App />
        <ToastContainer limit={3} pauseOnFocusLoss={false} autoClose={2500} hideProgressBar />
      </SkeletonTheme>
    </ThisRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
