import React from 'react';

export const BurgerIcon = () => {
  return (
    <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1739_17515)">
        <path d="M0 1.5498H28" stroke="#F3F3F3" strokeWidth="1.5" />
        <path d="M0 9.5498H28" stroke="#F3F3F3" strokeWidth="1.5" />
        <path d="M0 17.5498H28" stroke="#F3F3F3" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_1739_17515">
          <rect width="28" height="17.5" fill="white" transform="translate(0 0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};
