import React from 'react';

export const CopyIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.8374 4.12695V11.127C4.8374 12.0935 5.6209 12.877 6.5874 12.877H11.8374M4.8374 4.12695V2.37695C4.8374 1.41045 5.6209 0.626953 6.5874 0.626953H10.6C10.832 0.626953 11.0546 0.71914 11.2187 0.883235L15.0811 4.74567C15.2452 4.90977 15.3374 5.13233 15.3374 5.36439V11.127C15.3374 12.0935 14.5539 12.877 13.5874 12.877H11.8374M4.8374 4.12695H2.5874C1.89705 4.12695 1.3374 4.6866 1.3374 5.37695V14.627C1.3374 15.5935 2.1209 16.377 3.0874 16.377H10.5874C11.2778 16.377 11.8374 15.8173 11.8374 15.127V12.877"
        stroke="#B0B1B1"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
