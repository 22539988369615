import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  #group1 {
    animation: anim1 1000ms linear infinite normal forwards;

    @keyframes anim1 {
      0% {
        transform: translate(22.5px, 22.5px) scale(0.54413, 0.54413);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      100% {
        transform: translate(22.5px, 22.5px) scale(0.54413, 0.54413);
      }
    }
  }

  #group2 {
    animation: anim2 1000ms linear infinite normal forwards;

    @keyframes anim2 {
      0% {
        transform: translate(22.5px, 22.5px) scale(0.730119, 0.730119);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      50% {
        transform: translate(22.5px, 22.5px) scale(1.000001, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      100% {
        transform: translate(22.5px, 22.5px) scale(0.730119, 0.730119);
      }
    }
  }
`;

interface IProps {
  className?: string;
}

export const GreenDotIcon: FunctionComponent<IProps> = ({ className = '' }) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="45"
      height="45"
      className={className}
    >
      <defs>
        <radialGradient
          id="eIwOt30hH1u2-fill"
          cx="0"
          cy="0"
          r="1"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 22.5 -22.5 0 0 0)"
        >
          <stop id="eIwOt30hH1u2-fill-0" offset="0%" stopColor="rgba(124,227,203,0.68)" />
          <stop id="eIwOt30hH1u2-fill-1" offset="35.9141%" stopColor="rgba(124,227,203,0.44)" />
          <stop id="eIwOt30hH1u2-fill-2" offset="100%" stopColor="rgba(124,227,203,0)" />
        </radialGradient>
        <radialGradient
          id="eIwOt30hH1u3-fill"
          cx="0"
          cy="0"
          r="1"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 22.5 -22.5 0 0 0)"
        >
          <stop id="eIwOt30hH1u3-fill-0" offset="0%" stopColor="rgba(124,227,203,0.68)" />
          <stop id="eIwOt30hH1u3-fill-1" offset="35.9141%" stopColor="rgba(124,227,203,0.44)" />
          <stop id="eIwOt30hH1u3-fill-2" offset="100%" stopColor="rgba(124,227,203,0)" />
        </radialGradient>
      </defs>
      <g id="group1" transform="translate(22.5,22.5) scale(0.54413,0.54413)">
        <circle r="22.5" transform="translate(0,0)" fill="url(#eIwOt30hH1u2-fill)" />
      </g>
      <g id="group2" transform="translate(22.5,22.5) scale(0.730119,0.730119)">
        <circle r="22.5" transform="translate(0,0)" fill="url(#eIwOt30hH1u3-fill)" />
      </g>
      <circle r="11" transform="matrix(.960277 0 0 0.960277 22.5 22.5)" fill="#030303" />
      <circle r="8" transform="translate(22.5 22.5)" fill="#7ce3cb" />
    </StyledIcon>
  );
};
