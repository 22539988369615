import React, { FunctionComponent, memo, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ArrowUpIcon } from 'components/shared/icons';
import { COULDNT_FIND_RECORDS, COULDNT_FIND_RECORDS_DESC, EMPTY_TABLE, EMPTY_TABLE_DESC } from 'constants/messages';
import {
  StyledBorders,
  StyledEmptyTableRow,
  StyledPagination,
  StyledPaginationContainer,
  StyledRow,
  StyledTable,
  StyledWrapper,
} from 'components/shared/table/styles';
import { ISortingObj, ISortType } from 'entities/types/sorting';

export interface IColumn {
  label: string;
  sortable?: boolean;
  dataKey: string;
  render?: (dataKeyValue: any, row: any) => ReactNode;
  cellClassName?: string;
  columnClassName?: string;
}

interface IProps {
  columns: Array<IColumn>;
  rows: Array<Record<string, any>>;
  hiddenColumnsKeys?: Array<string>;
  onRowClick?: (row: IColumn) => void;
  totalItems?: number;
  currentPage?: number;
  sortObj?: ISortingObj;
  loading?: boolean;
  itemsPerPage?: number;
  className?: string;
  searchText?: string;
  onPageChange?: (newPage: number) => void;
  onSortChange?: (sortObj: ISortingObj) => void;
  withOverflowBorders?: boolean;
  emptyTableText?: string;
  emptyTableDescription?: string;
}

export const Table: FunctionComponent<IProps> = memo(
  ({
    columns = [],
    rows = [],
    hiddenColumnsKeys = [],
    onRowClick,
    searchText = '',
    totalItems = 10,
    currentPage = 1,
    sortObj = {},
    loading = false,
    itemsPerPage = 10,
    className = '',
    withOverflowBorders = true,
    onPageChange,
    onSortChange,
    emptyTableText = '',
    emptyTableDescription = '',
  }) => {
    const setPreciseColumnSorting = (columnDataKey: string, sortType: ISortType) => {
      // toggle off if already set
      if (sortObj.sortKey === columnDataKey && sortObj.sortType === sortType && onSortChange) {
        onSortChange({ sortType: '', sortKey: '' });
      } else if (onSortChange) {
        onSortChange({ sortKey: columnDataKey, sortType });
      }
    };

    const toggleColumnSorting = (columnDataKey: string) => {
      let newSortType: ISortType = '';
      if (sortObj.sortKey === columnDataKey && sortObj.sortType === 'asc') {
        newSortType = 'desc';
      } else if (sortObj.sortKey === columnDataKey && sortObj.sortType === 'desc') {
        newSortType = '';
      } else {
        newSortType = 'asc';
      }

      if (onSortChange) {
        onSortChange({ sortKey: columnDataKey, sortType: newSortType });
      }
    };

    const checkColumnSorted = (columnDataKey: string, sortType: ISortType) =>
      sortObj.sortKey === columnDataKey && sortObj.sortType === sortType;

    // ReactPaginate starts the count from 0
    // https://github.com/AdeleD/react-paginate/issues/167#issuecomment-388386261
    const handlePageChange = ({ selected }: { selected: number }) => {
      const newPage = selected + 1;

      if (!!onPageChange && typeof onPageChange === 'function') {
        onPageChange(newPage);
      }
    };

    const handleRowClick = (row: IColumn) => {
      if (!loading && onRowClick && typeof onRowClick === 'function') onRowClick(row);
    };

    const getColumnAriaLabel = (column: IColumn) => {
      let nextSortType = '';
      if (column.dataKey === sortObj.sortKey) {
        switch (sortObj.sortType) {
          case '':
            nextSortType = 'ascending';
            break;
          case 'asc':
            nextSortType = 'descending';
            break;
          case 'desc':
            nextSortType = 'disable';
            break;
          default:
            break;
        }
      } else {
        nextSortType = 'ascending';
      }

      return `sort column ${nextSortType}`;
    };
    const rowsToRender = loading ? Array.from(Array(itemsPerPage)).fill({}) : rows;
    const columnsToRender = columns.filter((column) => !hiddenColumnsKeys?.includes(column.dataKey));

    return (
      <StyledWrapper className={`table-container ${className}`}>
        <StyledBorders withOverflowBorders={withOverflowBorders} className="table-borders">
          <StyledTable className="table">
            <thead>
              <tr>
                {columnsToRender.map((column, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <th key={`${column.dataKey}-${index}`} className={column.columnClassName}>
                    <span
                      tabIndex={column.sortable && !loading ? 0 : -1}
                      role="button"
                      aria-label={column.sortable && !loading ? getColumnAriaLabel(column) : column.label}
                      className="column-label"
                      onClick={column.sortable && !loading ? () => toggleColumnSorting(column.dataKey) : undefined}
                      onKeyDown={(event) => {
                        if (column.sortable && !loading && event.key === 'Enter') {
                          toggleColumnSorting(column.dataKey);
                        }
                      }}
                    >
                      {column.label}{' '}
                    </span>
                    {column.sortable && (
                      <span className="column-actions">
                        <button
                          type="button"
                          aria-label={`sort column ${column.label} ascending ${
                            sortObj.sortKey === column.dataKey && sortObj.sortType === 'asc' ? 'disable' : 'enable'
                          }`}
                          className={`sort-btn sort-asc ${checkColumnSorted(column.dataKey, 'asc') ? 'active' : ''}`}
                          onClick={() => setPreciseColumnSorting(column.dataKey, 'asc')}
                          disabled={loading}
                        >
                          <ArrowUpIcon width={9} height={6} />
                        </button>
                        <button
                          type="button"
                          aria-label={`sort column ${column.label} descending ${
                            sortObj.sortKey === column.dataKey && sortObj.sortType === 'desc' ? 'disable' : 'enable'
                          }`}
                          className={`sort-btn sort-desc ${checkColumnSorted(column.dataKey, 'desc') ? 'active' : ''}`}
                          onClick={() => setPreciseColumnSorting(column.dataKey, 'desc')}
                          disabled={loading}
                        >
                          <ArrowUpIcon width={9} height={6} />
                        </button>
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsToRender.map((row, index) => (
                <StyledRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`row-${index}`}
                  tabIndex={!loading && onRowClick && typeof onRowClick === 'function' ? 0 : 1}
                  onClick={() => handleRowClick(row)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleRowClick(row);
                    }
                  }}
                  $loading={loading}
                >
                  {columnsToRender.map((column, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={`${column.dataKey}-${index}`} className={column.cellClassName}>
                      {loading ? (
                        <Skeleton />
                      ) : column.render && typeof column.render === 'function' ? (
                        column.render(row[column.dataKey], row)
                      ) : (
                        row[column.dataKey]
                      )}
                    </td>
                  ))}
                </StyledRow>
              ))}
              {rows.length === 0 && !loading && !!searchText && (
                <StyledEmptyTableRow className="table-empty-row">
                  <td colSpan={columnsToRender.length}>
                    <div className="error-msg">{`${COULDNT_FIND_RECORDS} “${searchText}”`}</div>
                    <div className="error-desc">{COULDNT_FIND_RECORDS_DESC}</div>
                  </td>
                </StyledEmptyTableRow>
              )}
              {rows.length === 0 && !loading && !searchText && (
                <StyledEmptyTableRow className="table-empty-row">
                  <td colSpan={columnsToRender.length}>
                    <div className="error-msg">{emptyTableText || EMPTY_TABLE}</div>
                    <div className="error-desc">{emptyTableDescription || EMPTY_TABLE_DESC}</div>
                  </td>
                </StyledEmptyTableRow>
              )}
            </tbody>
          </StyledTable>
          {/*
        // ReactPaginate starts the count from 0
        // https://github.com/AdeleD/react-paginate/issues/167#issuecomment-388386261
        */}
          <StyledPaginationContainer className="pagination-container">
            {rows.length !== 0 && onPageChange && (
              <StyledPagination
                nextLabel={
                  <span className="next-label">
                    Next{' '}
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ transform: 'rotate(180deg)' }}
                    >
                      <path
                        d="M5.5 11.001L0.5 6.00098L5.5 1.00098"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5 6.50098C12.7761 6.50098 13 6.27712 13 6.00098C13 5.72483 12.7761 5.50098 12.5 5.50098V6.50098ZM0.5 6.50098H12.5V5.50098H0.5V6.50098Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                }
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                pageCount={Math.ceil(totalItems / itemsPerPage)}
                previousLabel={
                  <span className="previous-label">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.5 11.001L0.5 6.00098L5.5 1.00098"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5 6.50098C12.7761 6.50098 13 6.27712 13 6.00098C13 5.72483 12.7761 5.50098 12.5 5.50098V6.50098ZM0.5 6.50098H12.5V5.50098H0.5V6.50098Z"
                        fill="currentColor"
                      />
                    </svg>{' '}
                    Previous
                  </span>
                }
                breakLabel="..."
                activeClassName="active"
                forcePage={currentPage - 1}
              />
            )}
          </StyledPaginationContainer>
        </StyledBorders>
      </StyledWrapper>
    );
  }
);
