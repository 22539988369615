import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  CANCEL,
  ORDER_HELMET_TITLE,
  ORDER_PAGE_HEADING,
  TELEGRAM_CONTACT_DESCRIPTION,
  TELEGRAM_CONTACT_HEADING,
  TELEGRAM_GROUP_NAME_PREFIX,
} from 'constants/messages';
import { Button } from 'components/shared/button/button';
import { EButtonVariants } from 'components/shared/button/button.entities';
import { useCartStore } from 'stores/cart/cart.store';
import { toast } from 'react-toastify';
import { RECAPTCHA_PUBLIC_KEY } from 'constants/constants';
import { Helmet } from 'react-helmet';
import Footer from 'components/layout/footer/footer';
import { TextInput } from 'components/shared/text-input/text-input';
import { FOOTER_HEIGHT_BIG, HEADER_HEIGHT_BIG } from 'constants/styles';
import { Api } from 'utils/api';
import { EContactOption } from 'entities/enums/contact-options';
import { debounce } from 'ts-debounce';
import { validateUserId } from 'utils/utils';
import { selectStyles } from './select-styles';
import ConditionsModal from './conditions-modal/conditions-modal';
import OrderSubmitted from './order-submited/order-submitted';

const StyledWrapper = styled.div`
  width: 505px;
  margin: 0 auto;
  min-height: calc(100vh - ${HEADER_HEIGHT_BIG}px - ${FOOTER_HEIGHT_BIG}px);

  @media only screen and (max-width: 525px) {
    padding: 0 15px;
    width: 100%;
  }
`;

const StyledHeading = styled.h3`
  font-weight: 300;
  font-size: 27px;
  line-height: 44px;
  color: #c3c4c3;
  padding-left: 8px;
  margin: 40px 0;
`;

const StyledIDInput = styled(TextInput)`
  .text-input-label {
    margin-top: 56px;
    margin-bottom: 16px;
  }
`;

const StyledAdditionalData = styled.div`
  margin-top: 66px;
  font-size: 15px;
  line-height: 28px;
  color: #c3c4c3;

  .additional-data-heading {
    font-weight: 600;
    font-size: 18px;
  }
`;

const StyledGroupInput = styled(TextInput)`
  margin-top: 16px;
  margin-bottom: 30px;

  .text-input-wrapper {
  }

  .input-error {
    width: 100%;
  }

  .text-input-wrapper {
    width: 100%;
  }
`;

const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  .back-btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #c3c4c3;
    cursor: pointer;
  }

  .next-btn {
    padding: 18px 44px;
    color: var(--font-color-white);

    &:disabled {
      color: rgba(243, 243, 243, 0.4);
    }
  }
`;

const ReCaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 66px;
`;

interface IInputError {
  show: boolean;
  message: string;
}

interface IOrderValidationErrors {
  userId: IInputError;
  telegramGroupName: IInputError;
}

const options = [
  { label: 'Telegram', value: EContactOption.TELEGRAM },
  { label: 'Discord', value: EContactOption.DISCORD },
  { label: 'Email', value: EContactOption.EMAIL },
];

const initialErrorObj: IOrderValidationErrors = {
  userId: { show: false, message: '' },
  telegramGroupName: { show: false, message: '' },
};

const OrderPage = () => {
  const navigate = useNavigate();
  const { cartItems, clearCart, chain } = useCartStore((store) => ({
    cartItems: store.cartItems,
    chain: store.chain,
    clearCart: store.clearStore,
  }));
  const [userId, setUserId] = useState('');
  const [telegramGroupName, setTelegramGroupName] = useState('');
  const [activeOption, setActiveOption] = useState(options[0]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>('');
  const [errors, setErrors] = useState(initialErrorObj);
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  const getUserIdPlaceholder = () => {
    switch (activeOption.value) {
      case EContactOption.TELEGRAM:
        return 'e.g. @cameraman';
      case EContactOption.DISCORD:
        return 'e.g. Username#0658';
      case EContactOption.EMAIL:
        return 'name@example.com';
      default:
        return '';
    }
  };

  const getUserIdLabel = () => {
    switch (activeOption.value) {
      case EContactOption.TELEGRAM:
        return 'Telegram username';
      case EContactOption.DISCORD:
        return 'Discord username';
      case EContactOption.EMAIL:
        return 'Email address';
      default:
        return '';
    }
  };

  const debounceUserIdValidation = useRef(
    debounce((userId: string, activeOptn: EContactOption, errors: IOrderValidationErrors) => {
      const userIdErrors = validateUserId(userId, activeOptn);
      setErrors({ ...errors, userId: userIdErrors });
    }, 700)
  ).current;

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleOptionChange = (newOption: any) => {
    setActiveOption(newOption);
    debounceUserIdValidation(userId, newOption.value, errors).then();
  };

  const handleIdInputChange = (newValue: string) => {
    setUserId(newValue);
    // reset error
    debounceUserIdValidation(newValue, activeOption.value, errors).then();
  };

  const handleTelegramInputChange = (newValue: string) => {
    setTelegramGroupName(newValue);
  };

  const handleConfirmSubmit = async () => {
    if (cartItems.length > 0 && !!chain?.id) {
      try {
        setSpinnerVisible(true);
        await Api.contactUs({
          contactOption: activeOption.value,
          userName: userId,
          groupName: telegramGroupName,
          token: captchaToken,
          order: {
            chainId: chain.id,
            chainName: chain?.name,
            items: cartItems.map(({ beacon, options }) => ({
              beaconName: beacon.name,
              beaconId: beacon.beaconId,
              coverage: options.coverage,
              months: options.months,
              fee: options.fee,
            })),
          },
        });
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setSpinnerVisible(false);
        closeConfirmModal();
        setFormSubmitted(true);

        clearCart();
      }
    } else {
      toast.error('Your cart is empty!!');
    }
  };

  const handleCaptcha = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (cartItems.length > 0) {
      setConfirmModalOpen(true);
    } else if (cartItems.length === 0) {
      toast.error('Your cart is empty!!');
    }
  };

  return (
    <div>
      <StyledWrapper>
        <Helmet>
          <title>{ORDER_HELMET_TITLE}</title>
        </Helmet>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={formSubmitted ? 'form-submitted' : 'form-not-submitted'}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames="fade"
          >
            {!formSubmitted ? (
              <div>
                <StyledHeading>{ORDER_PAGE_HEADING}</StyledHeading>
                <form autoComplete="false" onSubmit={handleSubmit}>
                  <div>
                    <Select
                      options={options}
                      styles={selectStyles}
                      isSearchable={false}
                      value={activeOption}
                      onChange={handleOptionChange}
                    />
                  </div>
                  <StyledIDInput
                    type="text"
                    id="userid-input"
                    label={getUserIdLabel()}
                    value={userId}
                    onChange={handleIdInputChange}
                    placeholder={getUserIdPlaceholder()}
                    error={errors.userId}
                  />
                  {activeOption.value === EContactOption.TELEGRAM && (
                    <StyledAdditionalData>
                      <div className="additional-data-heading">{TELEGRAM_CONTACT_HEADING}</div>
                      <div className="additional-data-description">{TELEGRAM_CONTACT_DESCRIPTION}</div>
                      <StyledGroupInput
                        id="telegram-group-input"
                        label={TELEGRAM_GROUP_NAME_PREFIX}
                        value={telegramGroupName}
                        onChange={handleTelegramInputChange}
                        placeholder="mygroupname"
                        error={errors.telegramGroupName}
                        inline
                      />
                    </StyledAdditionalData>
                  )}
                  <ReCaptchaWrapper className="recaptcha">
                    <ReCAPTCHA sitekey={RECAPTCHA_PUBLIC_KEY} onChange={handleCaptcha} size="normal" />
                  </ReCaptchaWrapper>
                  <StyledActionsContainer>
                    <button className="back-btn" type="button" onClick={() => navigate(-1)}>
                      {CANCEL}
                    </button>
                    <Button
                      label="Next"
                      type="submit"
                      variant={EButtonVariants.SECONDARY}
                      className="next-btn"
                      disabled={
                        activeOption.value === EContactOption.TELEGRAM
                          ? (!userId && !captchaToken) ||
                            (!telegramGroupName && !captchaToken) ||
                            errors.telegramGroupName.show ||
                            errors.userId.show
                          : (!userId && !captchaToken) || errors.userId.show
                      }
                      withUnderline
                    />
                  </StyledActionsContainer>
                </form>
              </div>
            ) : (
              <OrderSubmitted contactOption={activeOption.value} />
            )}
          </CSSTransition>
        </SwitchTransition>
        <Popup open={confirmModalOpen} onClose={closeConfirmModal}>
          <ConditionsModal onClose={closeConfirmModal} onSubmit={handleConfirmSubmit} spinnerVisible={spinnerVisible} />
        </Popup>
      </StyledWrapper>
      <Footer />
    </div>
  );
};

export default OrderPage;
