export const MONTHLY_PRICE_COLUMN_NAME = 'pricingCoverage';
export const DEBOUNCE_DELAY = 500;
export const RECAPTCHA_PUBLIC_KEY = '6LdmTxQgAAAAANAgQ8lO6RsyEDhXvAh-NoB3igy3';
export const REQUEST_DATA_LINK = 'https://forms.monday.com/forms/embed/f44d0ed9dfd0154885f48fdb3b87a489?r=use1';

export enum EMonthlyFilterValues {
  MIN_VALUE = 50,
  MAX_VALUE = 5000,
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
