import React, { FunctionComponent } from 'react';

export const SearchIcon: FunctionComponent<{ className?: string }> = ({ className = '' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <circle cx="9" cy="9.00098" r="8" stroke="#C3C4C3" strokeWidth="1.5" />
      <path d="M15 15.001L23 23.001" stroke="#C3C4C3" strokeWidth="1.5" />
    </svg>
  );
};
