import create from 'zustand';
import APIData from 'data/data.json';
import { filterItems, searchItems, sortItems } from 'stores/tables/helper/utils';
import { calculateChainValueFromHex, sortFilterOptions } from 'utils/utils';
import { Api } from 'utils/api';
import { IBeacon } from 'entities/types/beacon';
import { IFilterObj, IFilterOptions } from 'entities/types/filter';
import { ISortingObj } from 'entities/types/sorting';

const initialFilterObj: IFilterObj = {
  categories: [],
  chainIds: [],
  sourceNames: [],
};

interface ITableStore {
  beacons: Array<IBeacon>;
  dapis: Array<IBeacon>;
  totalDapis: number;
  totalBeacons: number;
  beaconFilterOptions: IFilterOptions;
  dapiFilterOptions: IFilterOptions;
  beaconFilterObj: IFilterObj;
  dapiFilterObj: IFilterObj;
  logoPaths: Record<string, string>;
  getOnChainValues: (items: Array<IBeacon>) => void;
  getBeacons: (sortObj: ISortingObj, filterObj: IFilterObj, page?: number, searchText?: string, limit?: number) => void;
  getDapis: (sortObj: ISortingObj, filterObj: IFilterObj, page?: number, searchText?: string, limit?: number) => void;
  onChainValues: Record<string, { value: number; timestamp: number; loading: boolean }>;
  onBeaconFilterChange: (newFilter: IFilterObj) => void;
  onDapiFilterChange: (newFilter: IFilterObj) => void;
  onClearBeaconFilter: () => void;
  onClearDapiFilters: () => void;
}

const useTablesStore = create<ITableStore>((set, get) => ({
  beacons: APIData.beacons,
  dapis: APIData.dapis,
  totalBeacons: APIData.beacons.length,
  totalDapis: APIData.dapis.length,
  beaconFilterOptions: sortFilterOptions(APIData.beaconFilterOptions),
  dapiFilterOptions: sortFilterOptions(APIData.dapiFilterOptions),
  beaconFilterObj: { ...initialFilterObj },
  dapiFilterObj: { ...initialFilterObj },
  onChainValues: {},
  logoPaths: APIData.logoPaths,
  onBeaconFilterChange: (newFilter) => set({ beaconFilterObj: newFilter }),
  onDapiFilterChange: (newFilter) => set({ dapiFilterObj: newFilter }),
  onClearBeaconFilter: () => set({ beaconFilterObj: { ...initialFilterObj } }),
  onClearDapiFilters: () => set({ dapiFilterObj: { ...initialFilterObj } }),
  getOnChainValues: (items: Array<any>) => {
    const chainValuesDictionary = get().onChainValues;
    const controlTimestamp = new Date().getTime() - 10 * 60 * 1000;

    items.forEach(async (item) => {
      const chainValueObj = chainValuesDictionary[`${item.beaconId}-${item?.chain?.id}`];
      const valuePresentButOld =
        !!chainValueObj &&
        !!chainValueObj.timestamp &&
        chainValueObj.timestamp <= controlTimestamp &&
        !chainValueObj.loading;

      const noValueAndNotLoading =
        !!chainValueObj &&
        chainValueObj?.value !== undefined &&
        !Number.isNaN(chainValueObj?.value) &&
        !chainValueObj?.loading;

      // if no values and not loading
      if (!chainValueObj || valuePresentButOld || !noValueAndNotLoading) {
        set((store) => ({
          onChainValues: {
            ...store.onChainValues,
            [`${item.beaconId}-${item?.chain?.id}`]: {
              ...store.onChainValues[`${item.beaconId}-${item?.chain?.id}`],
              loading: true,
            },
          },
        }));
        try {
          const { data } = await Api.getBeaconChainValue(item?.chain?.id, item?.beaconId);
          set((store) => ({
            onChainValues: {
              ...store.onChainValues,
              [`${item.beaconId}-${item.chain.id}`]: {
                ...store.onChainValues[`${item.beaconId}-${item?.chain?.id}`],
                value: calculateChainValueFromHex(data?.beaconResponse[0]?.hex, item.chainShiftValue),
                timestamp: new Date().getTime(),
                loading: false,
              },
            },
          }));
        } catch (e) {
          set((store) => ({
            onChainValues: {
              ...store.onChainValues,
              [`${item.beaconId}-${item?.chain?.id}`]: {
                ...store.onChainValues[`${item.beaconId}-${item?.chain?.id}`],
                loading: false,
              },
            },
          }));
        }
      }
    });
  },
  getBeacons: (sortObj, filterObj: IFilterObj, page = 1, searchText = '', limit = 10) => {
    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;

    const filteredBeacons = filterItems(APIData.beacons, filterObj);
    const sortedBeacons = sortItems(filteredBeacons, sortObj.sortKey, sortObj.sortType);
    const finalResult = searchItems(sortedBeacons, searchText);
    const displayedItems = finalResult.slice(startIndex, endIndex);

    get().getOnChainValues(displayedItems);

    set((state) => {
      return {
        ...state,
        beacons: displayedItems,
        totalBeacons: finalResult.length,
      };
    });
  },
  getDapis: (sortObj, filterObj: IFilterObj, page = 1, searchText = '', limit = 10) => {
    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;

    const filteredDapis = filterItems(APIData.dapis, filterObj);
    const sortedDapis = sortItems(filteredDapis, sortObj.sortKey, sortObj.sortType);
    const finalResult = searchItems(sortedDapis, searchText);
    const displayedItems = finalResult.slice(startIndex, endIndex);

    get().getOnChainValues(displayedItems);

    set((state) => {
      return {
        ...state,
        dapis: displayedItems,
        totalDapis: finalResult.length,
      };
    });
  },
}));

export default useTablesStore;
