import styled, { css } from 'styled-components';
import ReactPaginate from 'react-paginate';

export const StyledWrapper = styled.div`
  padding: 60px 0 20px 0;
`;
export const StyledBorders = styled.div<{ withOverflowBorders: boolean }>`
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  ${({ withOverflowBorders }) =>
    withOverflowBorders &&
    css`
      border: 1px solid #878888;
      padding: 0 40px;
    `}

  @media only screen and (max-width: 1020px) {
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: -40px;
  background-color: var(--color-black);

  thead tr {
    &:first-child {
      border-bottom: none;
    }
  }

  tbody tr {
    &:first-child {
      border-top: 1px solid #404141;
    }
  }

  .vertical-center {
    display: inline-flex;
    align-items: center;
  }

  td {
    font-weight: 300;
  }

  th,
  td {
    padding-left: 20px;
    padding-right: 24px;
    text-align: left;
  }

  tr {
    height: 82px;
  }

  tbody tr {
    border-top: 1px solid #404141;

    &:last-child {
      border-bottom: 1px solid #404141;
    }
  }

  .column-label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
  }

  & span.column-actions {
    position: relative;
    margin-left: 10px;

    .sort-btn {
      padding: 0;
      border: none;
      background-color: transparent;
      color: #6c6d6d;
      cursor: pointer;
    }

    .sort-btn.active {
      color: var(--font-color-white);
    }

    button.sort-desc {
      position: absolute;
      bottom: -7px;
      left: -1px;
      transform: rotate(180deg);
    }

    button.sort-asc {
      position: absolute;
      top: -5px;
      left: 0;
    }

    @media only screen and (max-width: 475px) {
      button.sort-asc {
        top: -9px;
      }

      button.sort-desc {
        bottom: -8px;
        transform: rotate(180deg);
      }
    }
  }

  .link {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const StyledEmptyTableRow = styled.tr`
  text-transform: none !important;
  border-top: none !important;

  & td {
    height: 167px;
    text-align: center;
    font-weight: 300;

    .error-msg {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 8px;
    }

    .error-desc {
      font-size: 12px;
      line-height: 19px;
      color: #878888;
    }
  }
`;

export const StyledPaginationContainer = styled.div`
  padding: 15px 0;
  margin-bottom: -40px;
  min-height: 85px;
  background-color: var(--color-black);
`;

export const StyledPagination = styled(ReactPaginate)`
  display: flex;
  width: 100%;
  list-style: none;
  justify-content: center;
  padding: 0;

  a {
    transition: color 200ms;
  }

  li.active a,
  li:hover a {
    color: var(--color-emerald);
  }

  li.disabled > a {
    color: rgba(64, 65, 65, 1);
    cursor: not-allowed;
  }

  @media only screen and (max-width: 420px) {
    margin: 0;

    .previous-label,
    .next-label {
      font-size: 0;
    }
  }

  & > li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #878888;
    user-select: none;

    &.previous {
      margin-left: 0;
      margin-right: 40px;
    }

    &.next {
      margin-right: 0;
      margin-left: 40px;
    }

    &.active {
      color: var(--font-color-white);
      text-decoration: underline;
    }

    a {
      font-size: 14px;
      line-height: 24px;
      padding: 15px 10px;
    }
  }
`;

export const StyledOverlapLogos = styled.span`
  display: inline-flex;

  svg {
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    transition: 200ms ease-in;

    &:nth-child(1) {
      display: block;
      z-index: 3;
      box-shadow: 0 3.2px 16px 3.2px rgba(16, 18, 17, 0.9);
    }

    &:nth-child(2) {
      left: -10px;
      z-index: 2;
    }

    &:hover {
      z-index: 10;
      box-shadow: 0 3.2px 16px 3.2px rgba(16, 18, 17, 0.9);
    }
  }
`;

export const StyledRow = styled.tr<{ $loading: boolean }>`
  ${({ $loading }) =>
    !$loading &&
    css`
      &:hover {
        background-color: #333030;
        cursor: pointer;
      }
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
    `}
  
  
  img {
    color: transparent;
  }
`;
