import { MONTHLY_PRICE_COLUMN_NAME } from 'constants/constants';
import { IFilterObj } from 'entities/types/filter';
import { IBeacon } from 'entities/types/beacon';

function filterItems(beacons: Array<IBeacon>, filterObj: IFilterObj) {
  return beacons.filter((item) => {
    if (
      (filterObj.chainIds.length > 0 && !filterObj.chainIds.includes(item.chain.id)) ||
      (filterObj.categories.length > 0 && !filterObj.categories.includes(item.category)) ||
      (filterObj.sourceNames.length > 0 && !filterObj.sourceNames.includes(item.source.name))
    ) {
      return false;
    }
    return true;
  });
}

function sortItems(items: Array<IBeacon>, sortColumn: string, sortType: string) {
  let result = items;
  if (items && sortColumn && sortType) {
    result = items.sort((a: any, b: any) => {
      if (sortColumn === 'name') {
        if (a[sortColumn] < b[sortColumn]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortColumn] > b[sortColumn]) {
          return sortType === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortColumn === 'source' || sortColumn === 'chain') {
        if (a[sortColumn].name < b[sortColumn].name) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortColumn].name > b[sortColumn].name) {
          return sortType === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortColumn === MONTHLY_PRICE_COLUMN_NAME) {
        if (a[sortColumn][0]?.subscriptionFee < b[sortColumn][0]?.subscriptionFee) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortColumn][0]?.subscriptionFee > b[sortColumn][0]?.subscriptionFee) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }

      if (a[sortColumn] < b[sortColumn]) {
        return sortType === 'asc' ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortType === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  return result;
}

function searchItems(items: Array<IBeacon>, searchTextRaw: string) {
  let result = items;
  if (items && searchTextRaw !== '') {
    const searchText = searchTextRaw.toLowerCase();
    result = items.filter((item) => {
      if (item?.fullName?.toLowerCase()?.includes(searchText)) {
        return true;
      }
      if (item?.source?.name?.toLowerCase()?.includes(searchText)) {
        return true;
      }
      if (item?.chain?.fullName?.toLowerCase()?.includes(searchText)) {
        return true;
      }
      if (item?.category?.toLowerCase()?.includes(searchText)) {
        return true;
      }
      return false;
    });
  }

  return result;
}

export { filterItems, sortItems, searchItems };
