import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { headerLinks } from 'constants/header-links';
import { REQUEST_DATA } from 'constants/messages';
import { containerStyles, HEADER_HEIGHT_BIG, MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT } from 'constants/styles';
import useWindowDimensions from 'hooks/window-dimensions';
import MobileMenu from 'components/mobile-menu/mobile-menu';
import { REQUEST_DATA_LINK } from 'constants/constants';
import { Button } from 'components/shared';
import { toast } from 'react-toastify';
import HeaderLink from './header-link/header-link';
import Logo from '../logo/logo';
import { EButtonVariants } from '../../shared/button/button.entities';
import { Cart } from '../../cart/cart';

const MOBILE_MENU_WIDTH = 670;

const StyledHeaderActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a.request-data-link:focus {
    outline: none;
    box-shadow: none;
  }
`;

const StyledRequestDataBtn = styled(Button)`
  margin-right: 35px;
  margin-left: 32px;
`;

const StyledWrapper = styled.header`
  background-color: var(--color-black);
  height: ${HEADER_HEIGHT_BIG}px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
  box-shadow: 1px 10px 36px 18px var(--color-black);
  display: flex;
  align-items: center;

  .container {
    ${containerStyles};
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: ${MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT}px) {
    box-shadow: none;
  }

  @media only screen and (max-width: 1100px) {
    ${StyledRequestDataBtn} {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: ${MOBILE_MENU_WIDTH}px) {
    width: 100%;

    ${StyledHeaderActions} {
      display: none;
    }

    .header-actions-container {
      display: flex;
      justify-content: right;
      width: 100%;
      margin-right: 5px;
      align-items: center;
    }
  }
`;

const Header = () => {
  const { width } = useWindowDimensions();
  const isMobileMenu = width <= MOBILE_MENU_WIDTH;

  const clearToasts = () => {
    toast.dismiss();
  };

  return (
    <StyledWrapper>
      <div className="container">
        <Link to="/">
          <Logo />
        </Link>
        <StyledHeaderActions>
          <div>
            {headerLinks.map((link) => (
              <HeaderLink key={link.path} to={link.path} label={link.label} />
            ))}
          </div>
          <div>
            {/* <Button */}
            {/*  label={FILE_CLAIM} */}
            {/*  variant={EButtonVariants.SECONDARY} */}
            {/*  onClick={() => console.log(1)} */}
            {/*  textTransform="uppercase" */}
            {/* /> */}
            <a href={REQUEST_DATA_LINK} target="_blank" rel="noreferrer" className="request-data-link">
              <StyledRequestDataBtn
                label={REQUEST_DATA}
                variant={EButtonVariants.PRIMARY}
                onClick={() => {}}
                textTransform="capitalize"
              />
            </a>
          </div>
        </StyledHeaderActions>
        <div className="header-actions-container">
          <Cart onOpen={clearToasts} />

          {isMobileMenu && <MobileMenu onOpen={clearToasts} />}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Header;
