import React, { FunctionComponent, memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickOutside from '@codecraftkit/clickoutside';
import { CSSTransition } from 'react-transition-group';
import { ICartItem, useCartStore } from 'stores/cart/cart.store';
import {
  COVERED_UP_TO,
  FINALIZE_ORDER,
  NO_VALUE,
  ORDER,
  POWERED_BY,
  TOTAL,
  TRIAL_UNTIL,
  USD,
} from 'constants/messages';
import { calculateUSDPrice, capitalize } from 'utils/utils';
import EmptyCart from 'components/cart/empty-cart/empty-cart';
import {
  StyledCartActionsContainer,
  StyledCartBtn,
  StyledCartCloseBtn,
  StyledCartDateFeed,
  StyledCartDeleteBtn,
  StyledCartEstimatedPrice,
  StyledCartPopoverContent,
  StyledCartPrimaryBtn,
  StyledCartTable,
  StyledCartTotalPrice,
} from 'components/cart/styles';
import { ShoppingListIcon, TimesIcon, BinIcon } from 'components/shared/icons';
import SimpleBar from 'simplebar-react';
import { ERoutePaths } from 'entities/enums/routes';
import useTablesStore from 'stores/tables/tables.store';
import DatafeedLogos from '../shared/datafeed/datafeed-logos';
import { EButtonVariants } from '../shared/button/button.entities';

const cartTransitionStyles: Record<string, any> = {
  entering: { right: '-100%', opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { right: '-100%', opacity: 0 },
};

const initialNavigationObj = { navigateToOrder: false, navigateHome: false };

interface IProps {
  onOpen: () => void;
  freeTrial?: boolean;
}

export const Cart: FunctionComponent<IProps> = memo(({ freeTrial = true, onOpen }) => {
  const navigate = useNavigate();
  const { cartItems, chain, removeCartItem } = useCartStore((store) => ({
    chain: store.chain,
    cartItems: store.cartItems,
    removeCartItem: store.removeCartItem,
  }));
  const { logoPaths } = useTablesStore((store) => ({ logoPaths: store.logoPaths }));
  const [isOpen, setIsOpen] = useState(false);
  const [navigateObj, setNavigateObj] = useState(initialNavigationObj);

  const openCart = () => {
    if (onOpen && typeof onOpen === 'function') {
      onOpen();
    }
    setIsOpen(true);
  };

  const closeCart = () => {
    setIsOpen(false);
  };

  const finalizeOrder = () => {
    closeCart();
    setNavigateObj({ ...navigateObj, navigateToOrder: true });
  };

  const handleRemoveCartItem = (beaconId: string, beaconType: string) => {
    removeCartItem(beaconId, beaconType);
  };

  const handleAnimationOver = () => {
    if (navigateObj.navigateToOrder) {
      setNavigateObj(initialNavigationObj);
      navigate(ERoutePaths.ORDER);
    } else if (navigateObj.navigateHome) {
      setNavigateObj(initialNavigationObj);
      navigate(ERoutePaths.HOME);
    }
  };

  const navigateToHomePage = () => {
    closeCart();
    setNavigateObj({ ...navigateObj, navigateHome: true });
  };

  const cartColumns = [
    {
      label: '',
      dataKey: 'delete',
      className: 'delete-column',
      render: (value: any, row: ICartItem) => (
        <StyledCartDeleteBtn onClick={() => handleRemoveCartItem(row?.beacon?.beaconId, row.beacon.type)}>
          <BinIcon />
        </StyledCartDeleteBtn>
      ),
    },
    {
      label: 'Data Feed',
      dataKey: 'beacon',
      render: (value: any, row: ICartItem) => (
        <StyledCartDateFeed>
          <div className="datafeed-name">
            <DatafeedLogos logoNames={row.beacon.logoNames} logoPaths={logoPaths} /> {row.beacon.name}
          </div>
          <div className="beacon-source">
            <span className="cart-entity-type">{row.beacon.type}</span>
            {POWERED_BY}
            <img height={20} alt="source logo" src={row.beacon.source.logoPath} /> {capitalize(row.beacon.source.name)}
          </div>
          {!freeTrial && (
            <div className="coverage">
              {COVERED_UP_TO} ${row?.options?.coverage} | ${row?.options?.fee} x {row?.options?.months} months
            </div>
          )}
        </StyledCartDateFeed>
      ),
    },
    {
      label: 'Price',
      dataKey: 'estimatedPrice',
      render: (value: any, row: ICartItem) => (
        <StyledCartEstimatedPrice>
          <div className="usd-price">
            {!freeTrial ? row.options.fee * row.options.months : NO_VALUE}{' '}
            <span className="cart-currency-label">{USD}</span>
          </div>
          <div className="token-price">
            {NO_VALUE} <span className="cart-currency-label">{row.beacon?.chain?.nativeToken}</span>
          </div>
        </StyledCartEstimatedPrice>
      ),
    },
  ];

  return (
    <ClickOutside handleAction={closeCart}>
      <StyledCartActionsContainer>
        <StyledCartBtn onClick={openCart}>
          <ShoppingListIcon /> <span className="count">{cartItems.length}</span>
        </StyledCartBtn>
      </StyledCartActionsContainer>
      <CSSTransition in={isOpen} timeout={200} classNames="fade" onExited={handleAnimationOver}>
        {(state) => (
          <StyledCartPopoverContent style={{ ...cartTransitionStyles[state] }}>
            <StyledCartCloseBtn onClick={closeCart}>
              <TimesIcon />
            </StyledCartCloseBtn>
            {cartItems.length > 0 ? (
              <>
                <div className="popover-header">
                  <div className="popover-header-title">
                    {ORDER} ({cartItems.length})
                  </div>
                  <div className="popover-header-mainnet">
                    <img alt="chain logo" src={chain?.orderLogoPath || chain?.logoPath} height={24} />
                    {`${chain?.fullName}`}
                  </div>
                </div>
                <div className="popover-description">
                  Other data feeds added to your order must be on {capitalize(chain?.fullName)} and must be paid in{' '}
                  {chain?.nativeToken}
                </div>
                <SimpleBar style={{ height: '300px', paddingRight: '10px' }} forceVisible="y" autoHide={false}>
                  <StyledCartTable columns={cartColumns} rows={cartItems} withOverflowBorders={false} />
                </SimpleBar>
                <StyledCartTotalPrice>
                  <div className="total-label">{TOTAL}</div>
                  <div className="total-price">
                    <div className="total-usd-price">
                      {!freeTrial ? `${calculateUSDPrice(cartItems)}` : NO_VALUE}{' '}
                      <span className="cart-currency-label">{USD}</span>
                    </div>
                    <div className="total-token-price">
                      {NO_VALUE} <span className="cart-currency-label">{chain?.nativeToken}</span>
                    </div>
                  </div>
                </StyledCartTotalPrice>
                <div className="free-trial-text">
                  <b>Free</b> {TRIAL_UNTIL}
                </div>
                <StyledCartPrimaryBtn
                  label={FINALIZE_ORDER}
                  onClick={finalizeOrder}
                  variant={EButtonVariants.PRIMARY}
                />
              </>
            ) : (
              <EmptyCart onNavigate={navigateToHomePage} />
            )}
          </StyledCartPopoverContent>
        )}
      </CSSTransition>
    </ClickOutside>
  );
});
