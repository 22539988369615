import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useTablesStore from 'stores/tables/tables.store';
import { DAPI_PAGE_DESCRIPTION, DAPI_PAGE_TITLE, DAPIS_HELMET_TITLE } from 'constants/messages';
import { Helmet } from 'react-helmet';
import DatafeedLogos from 'components/shared/datafeed/datafeed-logos';
import Skeleton from 'react-loading-skeleton';
import useWindowDimensions from 'hooks/window-dimensions';
import Footer from 'components/layout/footer/footer';
import { Table } from 'components/shared';
import { ISortingObj } from 'entities/types/sorting';
import { IFilterObj } from 'entities/types/filter';
import { TableToolbox } from 'components/shared/table/table-toolbox/table-toolbox';
import { IBeacon, IBeaconChain, IBeaconSource } from 'entities/types/beacon';
import { commonResponsiveTableStyles } from 'constants/styles';
import { capitalize } from 'utils/utils';
import { IColumn } from 'components/shared/table/table';
import { EEntitiesTableColumnClassNames } from 'entities/enums/column-classNames';
import PageHeader from '../../components/layout/page-header/page-header';

const StyledWrapper = styled.div`
  ${commonResponsiveTableStyles};
`;

const DApiPage = () => {
  const {
    dapis,
    totalDapis,
    getDapis,
    onChainValues,
    dapiFilterOptions,
    dapiFilterObj,
    onDapiFilterChange,
    onClearDapiFilters,
    logoPaths,
  } = useTablesStore((state) => ({
    dapis: state.dapis,
    getDapis: state.getDapis,
    totalDapis: state.totalDapis,
    onChainValues: state.onChainValues,
    dapiFilterOptions: state.dapiFilterOptions,
    dapiFilterObj: state.dapiFilterObj,
    onDapiFilterChange: state.onDapiFilterChange,
    onClearDapiFilters: state.onClearDapiFilters,
    logoPaths: state.logoPaths,
  }));
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortObj, setSortObj] = useState<ISortingObj>({ sortKey: '', sortType: '' });
  const { width } = useWindowDimensions();

  const handleSortingChange = (newObj: ISortingObj) => setSortObj(newObj);
  const handlePageChange = (newPage: number) => setCurrentPage(newPage);

  const updateDapiList = (sortObj: ISortingObj, filterObj: IFilterObj, page: number, searchValue: string) => {
    getDapis(sortObj, filterObj, page, searchValue);
  };

  useEffect(() => {
    updateDapiList(sortObj, dapiFilterObj, currentPage, searchValue);
  }, [currentPage, sortObj, dapiFilterObj, searchValue]);

  const handleSearchValues = (newValue: string) => {
    setSearchValue(newValue);
  };

  const handleFilterValues = (newFilter: IFilterObj) => {
    onDapiFilterChange(newFilter);
    // reset page
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    onClearDapiFilters();
    // reset page
    setCurrentPage(1);
  };

  const handleRowClick = (row: any) => {
    navigate(`${row.chain.name}/${row.name.replace('/', '-')}`);
  };

  const getHiddenColumns = useCallback(() => {
    switch (true) {
      case width <= 555:
        return ['category', 'pricingCoverage', 'onChainValue'];
      case width <= 875:
        return ['category', 'pricingCoverage'];
      case width <= 1020:
        return ['category'];
      default:
        return [];
    }
  }, [width]);

  const dapisColumns: Array<IColumn> = [
    {
      label: 'Data Feed',
      sortable: true,
      dataKey: 'name',
      columnClassName: EEntitiesTableColumnClassNames.DATAFEED,
      render: (value: string, row: IBeacon) => (
        <span className="vertical-center">
          <DatafeedLogos logoNames={row.logoNames} logoPaths={logoPaths} /> <strong>{value || ''}</strong>
        </span>
      ),
    },
    {
      label: 'Source',
      sortable: true,
      dataKey: 'source',
      columnClassName: EEntitiesTableColumnClassNames.SOURCE,
      render: (sourceObj: IBeaconSource) => (
        <span className="vertical-center" title={sourceObj.name}>
          <img alt={`logo for ${sourceObj.name}`} src={sourceObj.logoPath || ''} className="table-logo" />
          {capitalize(sourceObj.name || '')}
        </span>
      ),
    },
    {
      label: 'Network',
      sortable: true,
      dataKey: 'chain',
      columnClassName: EEntitiesTableColumnClassNames.NETWORK,
      render: (chainObj: IBeaconChain) => (
        <span className="vertical-center">
          <img alt={`logo for ${chainObj.fullName}`} className="table-logo" src={chainObj.logoPath || ''} />{' '}
          {chainObj.fullName}
        </span>
      ),
    },
    {
      label: 'On-chain Value',
      dataKey: 'onChainValue',
      columnClassName: EEntitiesTableColumnClassNames.ON_CHAIN_VALUE,
      render: (value: any, beacon: IBeacon) => (
        <span className="vertical-center">
          {!!onChainValues[`${beacon.beaconId}-${beacon.chain.id}`]?.value &&
          !onChainValues[`${beacon.beaconId}-${beacon.chain.id}`]?.loading ? (
            onChainValues[`${beacon.beaconId}-${beacon.chain.id}`]?.value?.toFixed(beacon.chainValueDecimals)
          ) : (
            <Skeleton width={60} />
          )}
        </span>
      ),
    },
    {
      label: 'Category',
      sortable: true,
      dataKey: 'category',
      columnClassName: EEntitiesTableColumnClassNames.CATEGORY,
    },
  ];

  return (
    <div>
      <StyledWrapper>
        <Helmet>
          <title>{DAPIS_HELMET_TITLE}</title>
        </Helmet>
        <PageHeader title={DAPI_PAGE_TITLE} description={DAPI_PAGE_DESCRIPTION} />
        <TableToolbox
          searchValue={searchValue}
          onSearchChange={handleSearchValues}
          filterOptions={dapiFilterOptions}
          filterObj={dapiFilterObj}
          onFilterChange={handleFilterValues}
          onClearFilters={handleClearFilters}
        />
        <Table
          columns={dapisColumns}
          rows={dapis}
          onRowClick={handleRowClick}
          totalItems={totalDapis}
          currentPage={currentPage}
          sortObj={sortObj}
          onPageChange={handlePageChange}
          onSortChange={handleSortingChange}
          hiddenColumnsKeys={getHiddenColumns()}
          searchText={searchValue}
        />
      </StyledWrapper>
      <Footer />
    </div>
  );
};

export default DApiPage;
