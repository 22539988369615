import React, { FunctionComponent } from 'react';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';

const StyledWrapper = styled.div<{ width?: number }>`
  width: 100%;
  margin: 0 16px 0 20px;
`;

const StyledSlider = styled(Slider)`
  height: 40px;

  &.rc-slider-disabled {
    background-color: transparent !important;

    .rc-slider-handle {
      display: none;
    }

    .rc-slider-mark-text {
      color: rgba(135, 136, 136, 1) !important;
    }
  }

  .rc-slider-rail {
    background-color: rgba(135, 136, 136, 1);
    height: 1px;
    margin-top: 3px;
  }

  .rc-slider-track {
    margin-top: 3px;
    height: 1px;
    background-color: var(--color-emerald);
  }

  .rc-slider-handle {
    top: 3px;
    width: 21px;
    height: 21px;
    background-color: var(--color-emerald);
    border: 3px solid #030303;
    box-shadow: 0 0 5.33333px var(--color-emerald);
    opacity: 1;
  }

  .rc-slider-handle-dragging {
    border-color: #030303 !important;
    box-shadow: 0 0 10px var(--color-emerald) !important;
  }

  .rc-slider-mark-text {
    top: 6px;
    color: #878888;
    white-space: nowrap;
  }

  .rc-slider-dot {
    width: 5px;
    height: 5px;
    background-color: rgba(135, 136, 136, 1);
    border-radius: 50%;
    border: 1px solid var(--color-black);
  }

  .rc-slider-mark-text-active {
    color: rgba(65, 118, 106, 1);
  }
`;

interface IProps extends SliderProps {
  wrapperClassName?: string;
}

export const CustomSlider: FunctionComponent<IProps> = ({
  wrapperClassName = '',
  value,
  marks = {},
  disabled = false,
  ...props
}) => {
  // change font color if mark value is exact
  const styledMarks = Object.keys(marks).reduce((acc, key, index) => {
    const intKey = parseInt(key, 10);
    // eslint-disable-next-line functional/immutable-data
    acc[index] = {
      label: marks[key],
      style:
        value === intKey || (Array.isArray(value) && value.includes(intKey)) ? { color: 'var(--color-emerald)' } : {},
    };
    return acc;
  }, {} as Record<string, any>);

  return (
    <StyledWrapper className={wrapperClassName}>
      <StyledSlider allowCross={false} {...props} value={value} marks={styledMarks} disabled={disabled} />
    </StyledWrapper>
  );
};
