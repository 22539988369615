import React, { FunctionComponent } from 'react';

interface IProps {
  className?: string;
}

export const ErrorIcon: FunctionComponent<IProps> = ({ className = '' }) => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.99991 6.66728V10.3339M9.99991 12.1673H10.0091M10.7912 1.93591L18.2023 13.9708C18.7038 14.7852 18.1179 15.8339 17.1616 15.8339H2.29446C1.33664 15.8339 0.751036 14.7823 1.25547 13.968L8.71145 1.93311C9.1901 1.1605 10.3146 1.16201 10.7912 1.93591Z"
        stroke="#D233F2"
        strokeWidth="1.22222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
