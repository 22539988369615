import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CHECK_BACK_LATER,
  CURRENT_SOURCES,
  DEVIATION,
  HISTORY,
  LAST_TRANSACTIONS_TITLE,
  POWERED_BY,
} from 'constants/messages';
import { useCartStore } from 'stores/cart/cart.store';
import { CustomLineChart, CopyContainer, CopyButton } from 'components/shared';
import useActiveEntityStore from 'stores/active-entity/active-entity.store';
import { CopyIcon, ExternalLinkIcon, StatusIcon } from 'components/shared/icons';
import Footer from 'components/layout/footer/footer';
import OnChainValue from 'components/page-entity/on-chain-value/on-chain-value';
import {
  StyledEntityAdditionalInfo,
  StyledEntityCurrencyAndStatus,
  StyledEntityIDDetails,
  StyledEntityInfo,
  StyledEntityPoweredBy,
  StyledEntityTabs,
  StyledEntityTransactions,
  StyledEntityTransactionsTable,
  StyledEntityWrapper,
  StyledMobileSubscribeBtn,
} from 'components/page-entity/styles';
import { Subscribe } from 'components/page-entity/subscribe/subscribe';
import useWindowDimensions from 'hooks/window-dimensions';
import { MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT } from 'constants/styles';
import { EButtonVariants } from 'components/shared/button/button.entities';
import { IColumn } from 'components/shared/table/table';
import DotDotDot from 'react-dotdotdot';
import { ETransactionCellClassNames } from 'entities/enums/cell-class-names';
import useTablesStore from 'stores/tables/tables.store';
import { capitalize } from 'utils/utils';
import DatafeedLogos from '../shared/datafeed/datafeed-logos';

interface IProps {
  type: 'beacon' | 'dapi';
}

export const PageEntity: FunctionComponent<IProps> = ({ type }) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const {
    activeBeacon,
    getActiveBeacon,
    getActiveDapi,
    recentTransactions,
    recentTransactionsLoading,
    chainValueLoading,
    historyChart,
    volatilityChart,
    getVolatilityChartData,
    getHistoryChartData,
    clearActiveEntity,
  } = useActiveEntityStore((store) => ({
    activeBeacon: store.activeBeacon,
    getActiveBeacon: store.getActiveBeacon,
    getActiveDapi: store.getActiveDapi,
    recentTransactions: store.recentTransactions,
    recentTransactionsLoading: store.recentTransactionsLoading,
    chainValueLoading: store.chainValueLoading,
    historyChart: store.historyChart,
    volatilityChart: store.volatilityChart,
    getVolatilityChartData: store.getVolatilityChartData,
    getHistoryChartData: store.getHistoryChartData,
    clearActiveEntity: store.clearStore,
  }));
  const { orderChain, addCartItem } = useCartStore((store) => ({
    addCartItem: store.addCartItem,
    orderChain: store.chain,
  }));
  const { logoPaths } = useTablesStore((store) => ({ logoPaths: store.logoPaths }));
  const { chainName = '', beaconId = '', dapiName = '' } = useParams();
  const [mobileSubscribeVisible, setMobileSubscribeVisible] = useState(false);

  useEffect(() => {
    const isFound =
      type === 'beacon' ? getActiveBeacon(chainName, beaconId) : getActiveDapi(chainName, dapiName.replace('-', '/'));
    if (!isFound) {
      navigate(-1);
    }
  }, [type]);

  useEffect(() => {
    return () => {
      clearActiveEntity();
    };
  }, []);

  const handleAddToCart = (coverage: number, subscriptionFee: number, months: number) => {
    if (activeBeacon) {
      addCartItem(activeBeacon, { coverage, months, fee: subscriptionFee });
    }
  };

  const getGithubLink = () => {
    const sourceName = activeBeacon?.source?.name?.toLowerCase();
    const beaconNameCamelCase = activeBeacon?.fullName?.toLowerCase().replace('/', '_');
    return `https://github.com/api3dao/operations/blob/main/data/apis/${sourceName}/beacons/${beaconNameCamelCase}.json`;
  };

  const handleSwitchTab = (index: number) => {
    if (activeBeacon?.name && activeBeacon?.chain?.id) {
      switch (index) {
        case 0:
          getHistoryChartData(activeBeacon?.beaconId, activeBeacon?.chain?.id);
          break;
        case 1:
          getVolatilityChartData(activeBeacon?.beaconId, activeBeacon?.chain?.id);
          break;
        default:
          break;
      }
    }
  };

  const openMobileSubscribe = () => {
    setMobileSubscribeVisible(true);
  };

  const closeMobileSubscribe = () => {
    setMobileSubscribeVisible(false);
  };

  const recentTransactionColumns: Array<IColumn> = [
    {
      label: 'Value',
      dataKey: 'chainValue',
      cellClassName: ETransactionCellClassNames.ON_CHAIN_VALUE,
      render: (value: any) => <span>{value}</span>,
    },
    { label: 'Timestamp', dataKey: 'timestamp', cellClassName: ETransactionCellClassNames.TIMESTAMP },
    {
      label: 'Transaction',
      dataKey: 'transactions',
      cellClassName: ETransactionCellClassNames.TRANSACTION,
      render: (value: any) => (
        <span>
          <a href={value.url} target="_blank" rel="noreferrer" className="link" title={value.transactionHash}>
            <DotDotDot clamp={2}>{value.transactionHash}</DotDotDot>
          </a>
        </span>
      ),
    },
    {
      label: '',
      dataKey: 'transactions',
      cellClassName: ETransactionCellClassNames.COPY,
      render: (value: any) => <CopyButton copyString={value?.transactionHash} />,
    },
  ];

  if (!activeBeacon) {
    return null;
  }

  const addToOrderDisabled = !!orderChain && activeBeacon?.chain?.id !== orderChain?.id;
  const mobileSubscribe = width <= MOBILE_SUBSCRIBE_WIDTH_BREAKPOINT;

  return (
    <StyledEntityWrapper>
      <StyledEntityInfo className="entity-info">
        <div className="entity-info-content">
          <StyledEntityIDDetails>
            <div className="entity-chain-logo">
              <img height={20} src={activeBeacon?.chain?.logoPath} alt="network logo" />
              {activeBeacon?.chain?.fullName}
            </div>
            <CopyContainer
              className="copy-container text-overflow-ellipsis"
              copyString={type === 'beacon' ? beaconId : dapiName}
            >
              {type === 'beacon' ? 'Beacon ID' : 'dAPI Name'}:{' '}
              <span className="entity-id">{type === 'beacon' ? beaconId : dapiName.toLowerCase()}</span>{' '}
              <button type="button" className="copy-btn">
                <CopyIcon />
              </button>
            </CopyContainer>
          </StyledEntityIDDetails>
          <StyledEntityCurrencyAndStatus mobileSubscribe={mobileSubscribe}>
            <div className="beacon-name">
              <StatusIcon active={activeBeacon?.chain?.additionalInfo?.active} className="status-icon" />
              <DatafeedLogos logoNames={activeBeacon?.logoNames} logoPaths={logoPaths} />
              <span className="beacon-name-label">{activeBeacon?.name}</span>
            </div>
            {mobileSubscribe && (
              <StyledMobileSubscribeBtn
                type="button"
                variant={EButtonVariants.PRIMARY}
                label="Subscribe options"
                className="mobile-subscribe-btn"
                onClick={openMobileSubscribe}
              />
            )}
          </StyledEntityCurrencyAndStatus>
          <StyledEntityAdditionalInfo>
            <StyledEntityPoweredBy>
              <div className="powered-by-label">{type === 'beacon' ? POWERED_BY : CURRENT_SOURCES}</div>
              <div className="provider">
                <img alt="provider-logo" src={activeBeacon?.source?.logoPath} height={32} />
                {capitalize(activeBeacon?.source?.name || '')}{' '}
                <a className="provider-link" href={activeBeacon.source.homepage} target="_blank" rel="noreferrer">
                  <ExternalLinkIcon />
                </a>{' '}
              </div>
              <div className="provider-description">{activeBeacon?.source?.description}</div>
            </StyledEntityPoweredBy>
            <OnChainValue
              onChainValue={activeBeacon.onChainValue}
              lastTransactionTimestamp={activeBeacon.lastTransactionTimestamp}
              loading={chainValueLoading}
              deviationThreshold={activeBeacon.chain.additionalInfo.airseekerConfig?.deviationThreshold}
              getGithubLink={getGithubLink}
            />
          </StyledEntityAdditionalInfo>
          <StyledEntityTabs onSelect={handleSwitchTab}>
            <TabList>
              <Tab>{HISTORY}</Tab>
              <Tab>{DEVIATION}</Tab>
            </TabList>
            <TabPanel>
              <CustomLineChart data={historyChart.data} seriesLabel="Beacon Value" />
            </TabPanel>
            <TabPanel>
              <CustomLineChart data={volatilityChart.data} seriesLabel="Deviation %" yAxisName="%" />
            </TabPanel>
          </StyledEntityTabs>
          <StyledEntityTransactions>
            <div className="recent-transactions-title">{LAST_TRANSACTIONS_TITLE}</div>{' '}
            <StyledEntityTransactionsTable
              columns={recentTransactionColumns}
              rows={recentTransactions}
              withOverflowBorders={false}
              itemsPerPage={5}
              loading={recentTransactionsLoading}
              emptyTableText={`This ${type} has no recent transactions`}
              emptyTableDescription={CHECK_BACK_LATER}
            />
          </StyledEntityTransactions>
        </div>
        <Footer isContainer={false} />
      </StyledEntityInfo>
      <Subscribe
        pricingCoverage={activeBeacon?.pricingCoverage}
        token={activeBeacon?.chain?.nativeToken ?? ''}
        onAddToCart={handleAddToCart}
        maxSubscriptionPeriod={activeBeacon?.source?.maxSubscriptionPeriod}
        addToOrderDisabled={addToOrderDisabled}
        cartProviderName={orderChain?.fullName}
        mobile={mobileSubscribe}
        mobileVisible={mobileSubscribeVisible}
        onCloseMobileSubscribe={closeMobileSubscribe}
        disabled
      />
    </StyledEntityWrapper>
  );
};
